import { IRecipeAction } from "../actions"
import { Types } from '../actions/types'
import { IRecipeState } from '../../model/state'
import { persistReducer } from "redux-persist";
import { TESTING } from "../../config"
import { Recipe } from "../../model";
import MFStorage from "./storage"

const initialState: IRecipeState = {
    isFetching: false,
    didFetch: false,
    dataLoaded: false,
    recipes: [],
    currentRecipe: null,
    currentAmount: 0,
    currentAlteredSections: {},
    preLoadedImages: true,
    currentSectionsDone: {}
}

//WARNING: REMOVE 'recipes' when deploying!
const config = {
    key: 'recipeState',
    storage: MFStorage,
    blacklist: ['isFetching', 'didFetch', 'dataLoaded', 'currentRecipe', 'currentAlteredSections', 'currentAmount', 'currentSectionsDone']
}

// ['isFetching', 'didFetch', 'dataLoaded', 'currentRecipe', 'currentAlteredSections', 'currentAmount']

const reducer =  (state = initialState, action: IRecipeAction): IRecipeState => {
    switch (action.type) {
        case Types.CHECK_RECIPES:
        //When in test mode always wait for fetch, otherwise data in local storage is enough 
            return Object.assign({}, state, {dataLoaded: state.recipes.length > 0 && (state.didFetch || !TESTING)})
        case Types.REQUEST_RECIPES:
            return Object.assign({}, state, {isFetching: true})
        case Types.RECEIVE_RECIPES:
            return Object.assign({}, state, {isFetching: false, didFetch: true, dataLoaded: true}, action.recipes)
        case Types.ERROR_RECIPES:
            return Object.assign({}, state, {error: action.error})
        case Types.SET_CURRENT_RECIPE:
            return Object.assign({}, state, {currentRecipe: getRecipeById(action.id, state.recipes)})
        case Types.SET_RECIPE_AMOUNT:
            return Object.assign({}, state, {currentAmount: action.amount})
        case Types.SET_ALTERED_SECTIONS:
            return Object.assign({}, state, {currentAlteredSections: action.sections})
        case Types.SET_REC_PRELOADED_IMAGES:
            return Object.assign({}, state, {preLoadedImages: action.didPreLoad})
        case Types.SET_SECTIONS_DONE:
            return Object.assign({}, state, {currentSectionsDone: action.sections})
        default: return state
    }
}

function getRecipeById(id: number, recipes: Recipe[]) {
    return recipes.find((r => r.id === id))
}

export default persistReducer(config, reducer)
