import React from 'react'
import {Component} from 'react'
import PantryContainer from './pantry_container';
import { NavigationScreenProps } from 'react-navigation';
import ShareButton from '../../components/nav_buttons/share_button'
import { Screen } from '../../interface';
import { Platform } from 'react-native';
import { Routes } from '../../navigation/stacks';


class Pantry extends Screen {

    state = {
        triggerShare: false,
        navTitle: ''
    }

    static navigationOptions = ({navigation}: NavigationScreenProps) => {
        return {
            // headerRight: <ShareButton onPress={navigation.getParam('buttonOnPress')} />,
        }
    }

    navigate() {
        if (Platform.OS === 'web') {
            this.props.webNavigate(Routes.RECIPE_OVERVIEW, this.state.navTitle)
        } else {
            this.props.navigation.navigate(Routes.RECIPE_OVERVIEW, {title: this.state.navTitle})
        }        
    }

    setTitle = (title: string) => {
        this.setState({navTitle: title})
    }

    componentDidMount() {
        if (Platform.OS !== 'web') {
            this.props.navigation.setParams({buttonOnPress: this.onTriggerShare})
        } else {
            this.props.webNavigation.setHeaderRight(
                // <ShareButton onPress={() => this.onTriggerShare()} />
            )
        }
    }

    onTriggerShare = () => { 
        this.setState({triggerShare: true})
    }

    onShareRespond = () => {
        this.setState({triggerShare: false})
    }

    render() {
        return <PantryContainer 
            // triggerShare={this.state.triggerShare} 
            // onShareRespond={this.onShareRespond}
            setTitle={this.setTitle} 
            onNavigate={() => this.navigate()} />
    }
}

export default Pantry
