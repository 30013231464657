import { Ingredient, Nutrition } from "../model";

export type CalculatedNutrion = {
    title: string,
    unit: string,
    amount: number,
    color: string,
    recommendedAmount: number
}

export interface CalculatedNutrions {
    protein: CalculatedNutrion,
    energiKj: CalculatedNutrion,
    energiKcal: CalculatedNutrion,
    carbs: CalculatedNutrion,
    vitamin_c: CalculatedNutrion,
    fibers: CalculatedNutrion,
    iron: CalculatedNutrion,
    calcium: CalculatedNutrion,
    vitamin_d: CalculatedNutrion,
    vitamin_b12: CalculatedNutrion,
    sodium: CalculatedNutrion,
    folate: CalculatedNutrion,
    selenium: CalculatedNutrion,
    iodine: CalculatedNutrion,
    fat_total: CalculatedNutrion,
    fat: {
        fat_mono: CalculatedNutrion,
        fat_total: CalculatedNutrion,
        // fat_trans: CalculatedNutrion,
        fat_poly: CalculatedNutrion,
        fat_sat: CalculatedNutrion,
        // fat_glycerol: CalculatedNutrion
    } 
} 





export function calculateNutritions(ingredients: {[key: string] : Ingredient}, nutritions: Nutrition[], splitAmount: number) {
    const calculatedNutritions : CalculatedNutrions = getEmptyCalculatedNutritions() 
    Object.keys(ingredients).forEach(i => {
        const ing = ingredients[i]
        const nuts = nutritions.filter(n => n.livsmedel_id === ing.externalId)
        calculatedNutritions.energiKj = createCalculatedNutrition(nuts.find(n => n.str_id === 'Enkj') , calculatedNutritions.energiKj, ing, splitAmount, '#002FB7', 820000)
        calculatedNutritions.energiKcal = createCalculatedNutrition(nuts.find(n => n.str_id === 'Ener') , calculatedNutritions.energiKcal, ing, splitAmount, '#64AF00', 196000)
        calculatedNutritions.protein = createCalculatedNutrition(nuts.find(n => n.str_id === 'Prot') , calculatedNutritions.protein, ing, splitAmount, '#0BDCED', 7240),
        calculatedNutritions.fat_total = createCalculatedNutrition(nuts.find(n => n.str_id === 'Fett') , calculatedNutritions.fat.fat_total, ing, splitAmount, '#4A6BFF', 7200)
        calculatedNutritions.carbs = createCalculatedNutrition(nuts.find(n => n.str_id === 'Kolh') , calculatedNutritions.carbs, ing, splitAmount, '#FF4A4A', 24170)
        calculatedNutritions.fibers = createCalculatedNutrition(nuts.find(n => n.str_id === 'Fibe') , calculatedNutritions.fibers, ing, splitAmount, '#FFC04A', 2460)
        calculatedNutritions.vitamin_c = createCalculatedNutrition(nuts.find(n => n.str_id === 'VitC') , calculatedNutritions.vitamin_c, ing, splitAmount, '#FFD02E', 7500)
        calculatedNutritions.iron = createCalculatedNutrition(nuts.find(n => n.str_id === 'Fe') , calculatedNutritions.iron, ing, splitAmount, '#F9FF4A', 900)
        calculatedNutritions.calcium = createCalculatedNutrition(nuts.find(n => n.str_id === 'Ca') , calculatedNutritions.calcium, ing, splitAmount, '#7EACFF', 80000)
        calculatedNutritions.vitamin_d = createCalculatedNutrition(nuts.find(n => n.str_id === 'VitD') , calculatedNutritions.vitamin_d, ing, splitAmount, '#5AFF4A', 2000)
        calculatedNutritions.vitamin_b12 = createCalculatedNutrition(nuts.find(n => n.str_id === 'VitB12') , calculatedNutritions.vitamin_b12, ing, splitAmount, '#FF9A4A', 200)
        calculatedNutritions.sodium = createCalculatedNutrition(nuts.find(n => n.str_id === 'Na') , calculatedNutritions.sodium, ing, splitAmount, '#7E4949', 230000)
        calculatedNutritions.folate = createCalculatedNutrition(nuts.find(n => n.str_id === 'Folat') , calculatedNutritions.folate, ing, splitAmount, '#496F7C', 30000)
        calculatedNutritions.selenium = createCalculatedNutrition(nuts.find(n => n.str_id === 'Se') , calculatedNutritions.selenium, ing, splitAmount, '#4F3C6D', 5000)
        calculatedNutritions.iodine = createCalculatedNutrition(nuts.find(n => n.str_id === 'I') , calculatedNutritions.iodine, ing, splitAmount, '#1A1313', 15000)
        calculatedNutritions.fat = {
            // fat_glycerol: createCalculatedNutrition(nuts.find(n => n.str_id === 'Ener') , calculatedNutritions.energiKcal, ing),
            fat_mono: createCalculatedNutrition(nuts.find(n => n.str_id === 'Mone') , calculatedNutritions.fat.fat_mono, ing, splitAmount,'#4AC2FF'),
            fat_poly: createCalculatedNutrition(nuts.find(n => n.str_id === 'Pole') , calculatedNutritions.fat.fat_poly, ing, splitAmount, '#0700BD'),
            fat_sat: createCalculatedNutrition(nuts.find(n => n.str_id === 'Mfet') , calculatedNutritions.fat.fat_sat, ing, splitAmount, '#BD5700'),
            fat_total: createCalculatedNutrition(nuts.find(n => n.str_id === 'Fett') , calculatedNutritions.fat.fat_total, ing, splitAmount, '#4A6BFF', 7200)
            // fat_trans: createCalculatedNutrition(nuts.find(n => n.str_id === 'Ener') , calculatedNutritions.energiKcal, ing)
        }
    })
    return calculatedNutritions
}



function createCalculatedNutrition(nutrition: Nutrition | undefined, calculatedNutrition: CalculatedNutrion, ingredient: Ingredient, splitAmount: number, color: string, maxRecommendation?: number) {   
    return {
        title: nutrition !== undefined ? nutrition.name : '',
        unit: nutrition !== undefined ? nutrition.unit : '',
        amount: nutrition !== undefined ? calculatedNutrition.amount + (nutrition.value*ingredient.weight/splitAmount) : calculatedNutrition.amount,
        color: color,
        recommendedAmount: maxRecommendation ? maxRecommendation : 0
    }
}



function getEmptyCalculatedNutritions() {
    const tempNut : CalculatedNutrion = {
        title: '',
        unit: '',
        amount: 0,
        color: 'black',
        recommendedAmount: 0
    }
    return {
        energiKj: tempNut,
        energiKcal: tempNut,
        protein: tempNut,
        fat_total: tempNut,
        carbs: tempNut,
        fibers: tempNut,
        vitamin_c: tempNut,
        iron: tempNut,
        calcium: tempNut,
        vitamin_d: tempNut,
        vitamin_b12: tempNut,
        sodium: tempNut,
        folate: tempNut,
        selenium: tempNut,
        iodine: tempNut,
        fat: {
            fat_mono: tempNut,
            // fat_trans: tempNut,
            fat_total: tempNut,
            fat_poly: tempNut,
            fat_sat: tempNut,
            // fat_glycerol: tempNut
        } 
    }
}

export function calFatEnergi(nuts: CalculatedNutrions, nut: CalculatedNutrion) {
    // const sum = nuts.carbs.amount + nuts.protein.amount + nuts.fat.fat_total.amount
    const sumKcal = nuts.energiKcal.amount
    let kcal = 0
    switch (nut.title) {
        case 'Protein':
            kcal = gramToKcal.protein
            break
        case 'Fibrer':
            kcal = gramToKcal.fibers
            break
        case 'Kolhydrater':
            kcal = gramToKcal.carbs
            break
        default:
            kcal = gramToKcal.fat
            break
    }
    return (nut.amount*kcal)/sumKcal *100
}

export const gramToKcal = {
    protein: 4,
    fat: 9,
    carbs: 4,
    fibers: 2
}
// function getRecomendation(title: string) {
//     const rec = {
//         "protein": 7240,
//         "energiKj": 820000,
//         "energiKcal": 196000,
//         "carbs": 24170,
//         "vitamin_c": 7500,
//         "fibers": 2460,
//         "iron": 900,
//         "calcium": 80000,
//         "vitamin_d": 2000,
//         "vitamin_b12": 200,
//         "sodium": 230000,
//         "folate": 30000,
//         "selenium": 5000,
//         "iodine": 15000,
//         "fat": 7200
//     } 
// }