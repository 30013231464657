import React, {Component} from 'react'
import {View, Text} from 'react-native'
import {Stacks} from '../app/navigation/stacks'
import WebRouter from './navigation/router'
import { IState } from '../app/model/state';
import { connect } from 'react-redux';

interface ReduxProps {
    dataLoaded: boolean
}


export interface ReactProps {}

type Props = ReduxProps & ReactProps

interface State {}

// Kanske onödig klass. Problemet låg inte här gällande async redux
class WebLoader extends Component<Props, State> {
    render() {
        // return this.props.dataLoaded ? (<WebRouter stacks={Stacks} />) : (<View><Text>Laddar...</Text></View>)   
        return <WebRouter stacks={Stacks} />
    }
}

const mapStateToProps = (state: IState) => {
    return {
        dataLoaded: state.recipeState.dataLoaded && state.categoryState.dataLoaded
    }
}

export default connect(mapStateToProps)(WebLoader)