import React from 'react'
import {Component} from 'react'
import { ShoppingList, ShoppingIngredient, IngredientBase, IngredientMeasures } from '../../../../model'
import { ScrollView, View, Platform, ImageSourcePropType, Image } from 'react-native'
import styles from './styles'
import { IState, IRecipeState, IShoppingListState } from '../../../../model/state'
import { IContainer } from '../../../../interface'
import { connect } from 'react-redux'
import CheckableItem from '../../../../components/checkable_item'
import MfText, { TEXT_STYLES } from '../../../../components/mf_text'
import { updateShoppingList, setCurrentShoppingList, addToShoppingList, addUserIngredient, setIngredientMeasure, removeShoppingList } from '../../../../redux/actions'
import Button, { BUTTON_VARIANTS } from '../../../../components/button/button'
import MfShare from '../../../../components/mf_share/'
import { getText } from '../../../../helpers/text/translation'
import AddIngredientModal from './add_ingredient_modal'
import MfContainer from '../../../../components/mf_container';
import { backgrounds } from '../../../../components/mf_container/mf_container';
import Alert from '../../../../components/alert';
import AnimatedListItem from '../../../../components/animated_list_item';
import QuantityHandler from '../../../../helpers/quantity_handler'
import MfCollapsible from '../../../../components/mf_collapsible';
import Touchable from '../../../../components/touchable';
import trashcan from '../../../../../assets/ui/trash_white.png'
import quantity_handler from '../../../../helpers/quantity_handler';
import { IS_WEB, WEB_SCROLLVIEW_STYLE } from '../../../../config/style/web_helper'
import { NAVBAR_SIZE } from '../../../../config/style'
import ChangeNameModal from './change_name_modal'


interface ReduxProps { 
    currentShoppingList: ShoppingList, 
    allIngredients: IngredientBase[], 
    lastUserIngredientId: number, 
    userIngredients: IngredientBase[], 
    recipes: IRecipeState ,
    shoppingListState: IShoppingListState,
    standardMeasures: IngredientMeasures
}
interface DispatchProps {
    updateShoppingList: (shoppingList: ShoppingList) => Promise<void>,
    setCurrentShoppingList: (id: number) => void,
    addToShoppingList: (id: number, s: ShoppingIngredient[]) => Promise<void>,
    addUserIngredient: (s: IngredientBase) => Promise<void>,
    setIngredientMeasures: (m: IngredientMeasures) => Promise<void>,
    removeShoppingList: (id:number) => Promise<void>
}
export interface ReactProps extends IContainer {
    triggerShare: boolean,
    onShareRespond: () => void,
    triggerRename: boolean,
    onRenameRespond: () => void,
    triggerDelete: boolean,
    onDeleteRespond: () => void,
    isTablet?: boolean,
    onDeleteGoBack: () => void,
}
type Props = ReduxProps & ReactProps & DispatchProps
interface State {}

interface CheckedIngredient {
    [key:number]: boolean
}

class ShoppingListDetailContainer extends Component<Props, State> {
    state = {
        checked: this.initChecked(),
        showDeleteButton: false,
        showDeleteModal: false,
        shareMessage: this.getShareMessage(),
        showAddModal: false,
        nextShoppingItemId: this.findHighestId()+1,
        deleteId: null,
        collapsedItem: null,
        unusedIngredients: this.getUnusedIngredients(),
        deleteList: false
    }

    constructor(p: Props) {
        super(p)
        this.ininMeasureProps()
    }

    ininMeasureProps() {
        if (Object.entries(this.props.standardMeasures).length === 0) {
            const sm = quantity_handler.getStandardQuantities(this.props.recipes.recipes)
            this.props.setIngredientMeasures(sm)
        }
    }
    initChecked() {
        const {ingredients} = this.props.currentShoppingList
        const obj : CheckedIngredient = {}
        ingredients.forEach(i => {
            obj[i.shoppingItemId] = false
        })
        return obj
    }

    getUnusedIngredients() {
        const arr = Array.from(this.props.userIngredients)
        const baseIngredients = arr.concat(this.props.allIngredients)
        return baseIngredients.filter(i => !(this.props.currentShoppingList.ingredients.map(ii => ii.id).includes(i.id)))
    }

    onPress(id: number) {
        const {checked} = this.state
        let obj : CheckedIngredient = checked
        obj[id] = !obj[id]
        this.setState({checked: obj, showDeleteButton: !this.checkedIsEmpty()})
    }

    onDeleteItem(id: number) {
        const {currentShoppingList} = this.props
        const arr = Array.from(currentShoppingList.ingredients)
        const ing = arr.find(i => i.shoppingItemId === id) as ShoppingIngredient
        const i = arr.indexOf(ing)
        arr.splice(i, 1)
        const newList : ShoppingList = {
            title: currentShoppingList.title,
            id: currentShoppingList.id,
            creationDate: currentShoppingList.creationDate,
            ingredients: arr
        }
        this.props.updateShoppingList(newList)
        .then(() => this.props.setCurrentShoppingList(currentShoppingList.id))
        const {checked} = this.state
        checked[id] = false
        this.setState({deleteId: null, checked: checked, showDeleteButton: !this.checkedIsEmpty()})
    }

    changeQuantity(increment: boolean, ingredient: ShoppingIngredient) {
        const {quantityShop} = ingredient
        const {currentShoppingList} = this.props
        let decimals = 0
        let quantityLengt = 1
        let changeAmount = 0.5
        if(Math.floor(quantityShop) != quantityShop) {
            decimals = ingredient.quantityShop.toString().split('.')[1].length || 0
        }
        if (decimals == 0) {
            quantityLengt = ingredient.quantityShop.toString().split('.')[0].length || 0
            switch(quantityLengt) {
                case 1:
                    changeAmount = 1
                    break
                case 2:
                    changeAmount = !increment ? quantityShop == 10 ? 1 : 10 : 10
                    break
                case 3:
                    changeAmount = !increment ? quantityShop == 100 ? 10 : 100 : 100
                    break
                case 4:
                    changeAmount =  100
                    break
                default:
                    changeAmount = 0.5
                    break
            }
        }

        const updatedIngredients = Array.from(currentShoppingList.ingredients).map(ing => {
            if (ing.id == ingredient.id) {
                const newAmount = increment ? ing.quantityShop+changeAmount : ing.quantityShop-changeAmount
                const shouldDecrease = newAmount > 0
                ing.quantityShop = shouldDecrease ? newAmount : ing.quantityShop
                return ing
            } else {
                return ing
            }
        })
        const newList : ShoppingList = {
            title: currentShoppingList.title,
            id: currentShoppingList.id,
            creationDate: currentShoppingList.creationDate,
            ingredients: updatedIngredients
        }
        this.props.updateShoppingList(newList)
        .then(() => this.props.setCurrentShoppingList(currentShoppingList.id))
        
    }

    onDeleteChecked() {
        const {checked} = this.state
        Object.keys(checked).forEach(key => {
            const id = parseInt(key)
            if (checked[id]) {
                this.onDeleteItem(id)
                delete checked[id]
                this.setState({showDeleteButton: false})
            }
        })
    }

    onDelete(id?: number) {
        if (id) {
            this.onDeleteItem(id)
        } else {
            this.onDeleteChecked()
        }
        this.setState({unusedIngredients: this.getUnusedIngredients()})
    }

    onSwipeItem(id: number) {
        setTimeout(() => {
            this.setState({deleteId: id})
        }, 50)
    }

    showDeleteModal() {
        this.setState({showDeleteModal: true})
    }

    onCloseDeleteModal() {
        this.setState({showDeleteModal: false})
    }

    onAddItems(items: ShoppingIngredient[]) {
        const {currentShoppingList} = this.props
        this.props.addToShoppingList(currentShoppingList.id, items)
        .then(() => {
            this.props.setCurrentShoppingList(currentShoppingList.id)
            this.setState({nextShoppingItemId: this.findHighestId()+1})
            this.setState({shareMessage: this.getShareMessage()})
        })
    }

    onShareReturn = () => {
        this.props.onShareRespond()
    }

    getShareMessage() {
        const {currentShoppingList} = this.props
        const lineBreak = Platform.OS === 'web' ? '%0D' : '\n'
        let message = `${getText('Inköpslista')} ${currentShoppingList.title}${lineBreak}`  
        message += `${getText('Skapelsedatum:')} ${currentShoppingList.creationDate}${lineBreak}`
        currentShoppingList.ingredients.forEach(i => {
            message += `${i.quantityShop} ${i.measureShop} ${i.name}${lineBreak}` 
        })
        return message
    }

    checkedIsEmpty() {
        const {checked} = this.state
        let isEmpty = true
        Object.keys(checked).forEach(key => {
            if (checked[parseInt(key)]) {
                isEmpty = false
            }
        })
        return isEmpty
    }

    onCloseAddModal = () => {
        this.setState({showAddModal: false})
    }

    findHighestId() {
        const {ingredients} = this.props.currentShoppingList
        return ingredients.length > 0 ? Math.max.apply(Math, ingredients.map(i => {return i.shoppingItemId})) : -1
    }

    getCollapsedItem(ingredient: ShoppingIngredient) {
        return (
            <View style={styles.collapsedContainer}>
                <Touchable fastResponse onPress={() => this.onSwipeItem(ingredient.shoppingItemId)}>
                    <View style={[styles.collapsedItem, styles.collapsedLeft]}>
                        <Image style={styles.collapsedImage} source={trashcan as ImageSourcePropType} />
                    </View>
                </Touchable>
                <Touchable fastResponse onPress={() => this.changeQuantity(false, ingredient)}>
                <View style={[styles.collapsedItem, styles.collapsedMiddle]}>
                        <MfText style={styles.collapsedText} textStyle={TEXT_STYLES.PLAIN_TEXT}>-</MfText>
                    </View>
                </Touchable>
                <Touchable fastResponse onPress={() => this.changeQuantity(true, ingredient)}>
                <View style={[styles.collapsedItem, styles.collapsedRight]}>
                    <MfText style={styles.collapsedText} textStyle={TEXT_STYLES.PLAIN_TEXT}>+</MfText>
                    </View>
                </Touchable>
            </View>
        )
    }

    collapseItem(id: number) {
        const {collapsedItem} = this.state
        this.setState({collapsedItem: collapsedItem == id ? null : id})
    }

    onDeleteList() {
        const {currentShoppingList} = this.props
        if (Platform.OS === 'web') {
            currentShoppingList.ingredients.forEach(ing => {
                this.setState({deleteId: ing.id})
            })
            this.setState({deleteList: true})
            this.onDeleteListEnd(true)
        } else {
            if (currentShoppingList.ingredients.length === 0) {
                this.onDeleteListEnd(true)
            } else {
                this.setState({deleteList: true})
            }
        }
    }

    onDeleteListEnd(run?: boolean) { 
        if (run) {
            const {currentShoppingList, removeShoppingList} = this.props
            removeShoppingList(currentShoppingList.id)
            this.props.onDeleteGoBack()
        }       
    }

    onCloseDeleteListModal() {
        this.props.onDeleteRespond()
    }

    sortIngredients(ingredients: ShoppingIngredient[]) {
        const cats : {[key: string]: ShoppingIngredient[]} = {}
        ingredients.forEach(ing => {
            if (ing.category in cats) {
                cats[ing.category].push(ing)
            } else {
                cats[ing.category] = [ing]
            }
        })
        const catArray = Object.keys(cats).map(cat => {
            return {
                title: cat,
                ingredients: cats[cat].sort((a,b) => {
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;
                    return 0;
                })
            }
        })
        return catArray
    }
    
    onChangeName(newName: string) {
        const {currentShoppingList} = this.props
        const updatedList : ShoppingList = {
            title: newName,
            creationDate: currentShoppingList.creationDate,
            ingredients: Array.from(currentShoppingList.ingredients),
            id: currentShoppingList.id
        }
        this.props.updateShoppingList(updatedList)
        .then(() => {
            this.props.setTitle(newName)
            this.props.onRenameRespond()
        })
    }

    actionOnPress() {
        this.setState({showAddModal: true, unusedIngredients: this.getUnusedIngredients()})
    }

    render() {
        const {currentShoppingList, allIngredients, userIngredients, triggerDelete, isTablet, triggerRename} = this.props
        this.sortIngredients(currentShoppingList.ingredients)
        const {
            showDeleteModal, 
            showDeleteButton, 
            showAddModal, 
            deleteId, 
            collapsedItem, 
            unusedIngredients, 
            deleteList
        } = this.state
        const webScroll = {
            height: WEB_SCROLLVIEW_STYLE.height,
            marginBottom: NAVBAR_SIZE.height
        }
        return (
            <MfContainer noMargin={isTablet || IS_WEB} backgroundImage={backgrounds.SHOPPING_LIST} style={styles.container}>
            <Alert 
                title={getText('Rensa')} 
                content={getText('Vill du rensa markerade varor?')} 
                onAccept={() => this.onDeleteChecked()}
                onDenied={() => this.onCloseDeleteModal()}
                onClose={() => this.onCloseDeleteModal()}
                visible={showDeleteModal}
                />
            <Alert 
                title={getText('Ta bort')} 
                content={getText('Vill du ta bort den här inköpslistan?')}
                onAccept={() => this.onDeleteList()}
                onDenied={() => this.onCloseDeleteListModal()}
                onClose={() => this.onCloseDeleteListModal()}
                visible={triggerDelete}
                />
            <ChangeNameModal
                visible={triggerRename}
                onCancel={() => this.props.onRenameRespond()}
                onChangeName={(s: string) => this.onChangeName(s)}
                currentName={currentShoppingList.title}
                />
            <AddIngredientModal 
                ingredients={unusedIngredients} 
                standardMeasures={this.props.standardMeasures}
                visible={showAddModal} 
                onClose={this.onCloseAddModal}
                lastUserIngredientId={this.props.lastUserIngredientId}
                onAddUserIngredient={this.props.addUserIngredient} 
                nextShoppingItemId={this.state.nextShoppingItemId}
                onAdd={(items: ShoppingIngredient[]) => this.onAddItems(items)} />

            {this.props.triggerShare ? <MfShare onReturn={this.onShareReturn} message={this.state.shareMessage} /> : null }

                <ScrollView contentContainerStyle={IS_WEB ? webScroll : styles.contentContainer}>
                    {currentShoppingList && currentShoppingList.ingredients.length > 0 ? this.sortIngredients(currentShoppingList.ingredients).map(cat => {
                        return(
                            <View key={cat.title}>
                                {/* <View style={styles.categoryTitle}>
                                    <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{cat.title}</MfText>
                                </View> */}
                                <View style={styles.catDivider}></View>
                                {cat.ingredients.map(ingredient => {
                                const isChecked = this.state.checked[ingredient.shoppingItemId]
                                const roundedQuantity = ingredient.roundUpShop ? QuantityHandler.roundQuantity(ingredient.quantityShop) : ingredient.quantityShop
                                const quantity = roundedQuantity > 0 ? QuantityHandler.convertQuarters(roundedQuantity) : ''
                                return (
                                    <AnimatedListItem 
                                        key={ingredient.shoppingItemId} 
                                        onAnimationEnd={
                                            () => deleteList ? 
                                            this.onDeleteListEnd() : this.onDelete(ingredient.shoppingItemId)
                                        } 
                                        deleteAnimation={deleteId === ingredient.shoppingItemId || deleteList}>
                                            <CheckableItem 
                                                style={[styles.checkable, styles.white]}
                                                checked={isChecked} 
                                                checkTouchable={true}
                                                onCheckPress={() => this.onPress(ingredient.shoppingItemId)}
                                                customComponent={
                                                    <View style={[styles.itemContainer]}>
                                                        <MfText style={[styles.itemTitle, isChecked ? styles.checkedText : null]} textStyle={TEXT_STYLES.PLAIN_TEXT}>{ingredient.name}</MfText>
                                                        <MfText style={[styles.itemQuantity, isChecked ? styles.checkedText : null]} textStyle={TEXT_STYLES.PLAIN_TEXT}>{`${quantity} ${ingredient.measureShop}`}</MfText>
                                                    </View>
                                                }
                                                onPress={() => this.collapseItem(ingredient.id)} 
                                                />
                                        <MfCollapsible collapsed={collapsedItem != ingredient.id}>
                                            {this.getCollapsedItem(ingredient)}
                                        </MfCollapsible>
                                    </AnimatedListItem>
                                )
                                }) 
                                }
                        </View>)
                        
                    }): 
                    <View style={styles.emptyContainer}>
                        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{getText("Tryck på + för att lägga till ingredienser")}</MfText>
                    </View>}
                    <View style={styles.bottomSpacer}></View>
                </ScrollView>
                <Button style={styles.actionButton} title={''} variant={BUTTON_VARIANTS.FLOATING_ACTION} onPress={() => this.actionOnPress()} /> 
                {showDeleteButton ? 
                    <Button style={styles.deleteButton} title={getText('Ta bort markerade')} variant={BUTTON_VARIANTS.NO_RADIUS} onPress={() => this.showDeleteModal()} />    
                : null}
            </MfContainer>
        )
    }

}

const mapStateToProps = (state: IState) => {
    return {
        currentShoppingList: state.shoppingListState.currentShoppingList,
        recipes: state.recipeState,
        shoppingListState: state.shoppingListState,
        allIngredients: state.ingredientState.ingredients,
        lastUserIngredientId: state.ingredientState.lastId,
        userIngredients: state.ingredientState.userIngredients,
        standardMeasures: state.ingredientState.standardMeasure
    } as ReduxProps
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        updateShoppingList: (s: ShoppingList) => dispatch(updateShoppingList(s)),
        setCurrentShoppingList: (id: number) => dispatch(setCurrentShoppingList(id)),
        addToShoppingList: (id: number, s: ShoppingIngredient[]) => dispatch(addToShoppingList(id, s)),
        addUserIngredient: (s: IngredientBase) => dispatch(addUserIngredient(s)),
        setIngredientMeasures: (m: IngredientMeasures) => dispatch(setIngredientMeasure(m)),
        removeShoppingList: (id: number) => dispatch(removeShoppingList(id)),
    } 
}

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(mapStateToProps, mapDispatchToProps)(ShoppingListDetailContainer)
