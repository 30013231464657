import {StyleSheet, Platform} from 'react-native'

export default StyleSheet.create({
   container: {
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        flexDirection: 'row',
        paddingBottom: 20
   }
})