import {StyleSheet} from 'react-native'
import { PRIMARY_THEME_COLORS } from '../../../config/style'

export default StyleSheet.create({
    modalContainer: {
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.9)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        padding: 20,
        margin: 20,
        backgroundColor: 'white',
        justifyContent: 'center',
        borderRadius: 5,
        alignItems: 'center',
        maxWidth: 700
    },
    buttonContainer: {
        justifyContent: 'space-around',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
        maxWidth: 520,
        alignItems: 'center'
    },
    text: {
        marginBottom: 10
    },
    button: {
        margin: 10
    },
    buttonYes: {
        backgroundColor: PRIMARY_THEME_COLORS.green
    },
    buttonNo: {
        backgroundColor: PRIMARY_THEME_COLORS.red
    }
})