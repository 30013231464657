import React, { createRef } from 'react'
import {Component} from 'react'
import styles from './styles'
import {Animated, View, TouchableWithoutFeedback, TouchableHighlight, TouchableOpacity, GestureResponderEvent, StyleProp, ViewStyle, LayoutChangeEvent, PanResponder, PanResponderStatic} from 'react-native'

interface ITouchable {
    style?: StyleProp<ViewStyle>,
    onPress: Function,
    type?: TOUCHEABLE_TYPES,
    fastResponse?: boolean
}

export enum TOUCHEABLE_TYPES {
    STANDARD = 'standard',
    OPACITY = 'opacity',
    HIHGLIGHT = 'hightlight'
}



class Touchable extends Component<ITouchable> {

    state = {
        top: 0,
        right: 0,
        opacityAnim: new Animated.Value(0),
        scaleAnim: new Animated.Value(0),
        height: 0,
        width: 0
    }

    onLayout(e: LayoutChangeEvent) {
        const {height, width} = this.state
        if (height === 0 &&  width === 0) {
            this.setState({
                height: e.nativeEvent.layout.height,
                width: e.nativeEvent.layout.width
            }, () => this.calculateScale())
        }
    }

    

    calculateScale() {
        const {top, right, width, height} = this.state
        let scale = height <= width ? width : height 
        scale += 100
        const middleX = width / 2
        const middleY = height / 2
        const scaleXY = top >= right ? top : right
        const difXY = middleX < right ? right : width - right


        const diffX = middleX < right ? right : width - right
        const diffY = middleY < top ? top : height - top

        scale += scaleXY + difXY
        
        return scale
    }

    onPressIn(e: GestureResponderEvent) {
        this.setState({right: e.nativeEvent.locationX, top: e.nativeEvent.locationY})
    }

    onPressOut() {
        const duration = 100
        const afterDuration = 10
        if (this.props.fastResponse) this.props.onPress()
        Animated.sequence([
            Animated.parallel([
                Animated.timing(this.state.opacityAnim, {
                    toValue: 0.5,
                    duration: duration
                }),
                Animated.timing(this.state.scaleAnim, {
                    toValue: this.calculateScale(),
                    duration: duration
                })
            ]),
            Animated.parallel([
                Animated.timing(this.state.opacityAnim, {
                    toValue: 0,
                    duration: afterDuration
                }),
                Animated.timing(this.state.scaleAnim, {
                    toValue: 0,
                    duration: afterDuration
                })
            ])
        ]).start((f) => {
            if (f.finished && !this.props.fastResponse) this.props.onPress() 
        })
    }

    ripple() {

    }
    render() {
        const {top, right} = this.state
        switch (this.props.type) {
            case TOUCHEABLE_TYPES.HIHGLIGHT:
                return (
                    <TouchableHighlight style={this.props.style} onPress={() => this.props.onPress()}>
                        {this.props.children}
                    </TouchableHighlight>
                )
            case TOUCHEABLE_TYPES.OPACITY:
                return (
                    <TouchableOpacity style={this.props.style} onPress={() => this.props.onPress()}>
                        {this.props.children}
                    </TouchableOpacity>
                )
            default:
                return (
                    <View 
                        style={[this.props.style,]} 
                        onStartShouldSetResponderCapture={() => true} 
                        onResponderStart={(e: GestureResponderEvent) => this.onPressIn(e)}
                        onResponderRelease={() => this.onPressOut()}
                        onLayout={(e: LayoutChangeEvent) => this.onLayout(e)}
                        > 
                        <View pointerEvents="none" style={styles.container} >
                            {this.props.children}
                            <Animated.View style={[
                                    styles.animView, 
                                    {top: top, left: right},
                                    {opacity: this.state.opacityAnim},
                                    {transform: [
                                        {scaleY: this.state.scaleAnim},
                                        {scaleX: this.state.scaleAnim}
                                    ]}
                                ]}/>
                        </View>
                    </View>
                )
        }
        
    }
}

export default Touchable
