import React from 'react'
import {Component} from 'react'
import styles from './styles'
import {Text, Animated} from 'react-native'

export enum TEXT_STYLES {
    HEADER = 'header',
    PLAIN_TEXT = 'plain_text',
    SUB_HEADER = 'sub_header',
    CARD_HEADER = 'card_header',
    BOLD_SUBHEADER = 'bold_sub_header',
    REGULAR_BUTTON = 'regular_button',
    SMALL_TEXT = 'small_text',
    BOLD_PLAIN = 'bold_plain'

}

interface IMfText {
    textStyle: TEXT_STYLES,
    style?: Object,
    animateColor?: boolean,
    from?: string,
    to?: string,
    duration?: number,
    delay?: number
}

class MfText extends Component<IMfText> {

    state = {
        textColorAnim: new Animated.Value(0)
    }

    componentDidMount() {
        if(this.props.animateColor) this.animateTextColor()
    }

    animateTextColor() {
        const {duration, delay} = this.props
        Animated.sequence([
            Animated.timing(this.state.textColorAnim,{
                toValue: 1,
                duration: duration
            }),
            Animated.timing(this.state.textColorAnim,{
                toValue: 0,
                duration: duration
            }),
        ]).start()
    }
    
    getStyle() {
        switch (this.props.textStyle) {
            case TEXT_STYLES.HEADER:
                return styles.header
            case TEXT_STYLES.PLAIN_TEXT:
                return styles.plainText
            case TEXT_STYLES.SUB_HEADER:
                return styles.subHeader
            case TEXT_STYLES.CARD_HEADER:
                return styles.cardHeader
            case TEXT_STYLES.BOLD_PLAIN:
                return styles.boldPlain
            default:
                return {}
        }    
    }

    render() {
        const {animateColor, from, to} = this.props
        if (animateColor && from && to) {
            let color = this.state.textColorAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [from, to]
            });
            return (
                <Animated.Text style={[this.getStyle(), this.props.style, {
                    color: color
                }]}>
                    {this.props.children}
                </Animated.Text>
            )
        }
        
        return (
            <Text style={[this.getStyle(), this.props.style, ]}>
                {this.props.children}
            </Text>
        )
    }
}

export default MfText
