import React from 'react'
import {Component} from 'react'
import styles from './styles'
import { View, Image, ImageSourcePropType } from 'react-native';
import MfCollapsible from '../mf_collapsible';
import { Step } from '../../model';
import RecipeStep from '../checkable_item';
import quantityHandler from '../../helpers/quantity_handler'
import MfText, { TEXT_STYLES } from '../mf_text';
import Touchable from '../touchable';
import check from '../../../assets/mf_ui/bockav-ikon-8cb414.png'


interface IRecipeSection {
    steps: Step[],
    id: number,
    title: string,
    amount: number,
    onSectionDone: Function,
    onStepDone: (steps: CheckedStep, id: number) => void,
    minAmount: number,
    doneSteps?: CheckedStep
}

export interface CheckedStep {
    [key:number]: boolean
}

interface State {
    checked: CheckedStep,
    isCollapsed: boolean,
    sectionDone: boolean
}

const image = {
    uri: check
}

class RecipeSection extends Component<IRecipeSection, State> {

    state = {
        checked: {},
        isCollapsed: false,
        sectionDone: false
    }

    convertIngredients(step: Step, numPortions: number, minPortions: number) {
        let regexp = /(?:\[#)([^\]]*)(?:\])/
        let markup
        let elems = []

        let stepText = step.stepText 
        let match //match[0] includes [# ], match[1] excludes them.
        let textParts: string[] = ['', stepText]
        let keyIndex = 0
        while ((match = regexp.exec(stepText)) !== null) {
            let parts = match[1].split( ':');
            let id = parseInt(parts[0])
            parts = parts[1].split('|');
            let replacement = '';
            step.ingredients.forEach( ingredient => {
                if (id == ingredient.useId){
                    let usePlural = quantityHandler.shouldUsePlural(ingredient, numPortions, minPortions);
                    replacement += quantityHandler.quantityStringForPortions(ingredient, numPortions, minPortions) + " "
                    if (!(ingredient.measure === 'styck' || ingredient.measure === 'stycken')){
                        replacement += usePlural ? ingredient.measurePlural : ingredient.measure;
                        replacement += " ";
                    }
                    replacement += usePlural ? parts[1] : parts[0];
                }
            })
            textParts = stepText.split(match[0])
            elems.push(textParts[0])
            elems.push(<MfText key={keyIndex} textStyle={TEXT_STYLES.BOLD_PLAIN}>{replacement}</MfText>)
            stepText = textParts[1]
            keyIndex++
        }
        elems.push(textParts[1])
        return (<MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{elems}</MfText>)
    }

    componentDidMount() {
        this.initChecked()
    }

    initChecked() {
        const {steps, doneSteps} = this.props
        const obj : CheckedStep = {}
        steps.forEach(step => {
            obj[step.id] = doneSteps !== undefined && step.id in doneSteps ? doneSteps[step.id] : false
        })
        this.setState({checked: obj}, () => this.setSectionDone())
        
    }

    onPress(id: number) {
        const {checked} = this.state
        let obj : CheckedStep = checked
        obj[id] = !obj[id]
        this.setState({checked: obj})
        this.props.onStepDone(obj, this.props.id) 
        this.setSectionDone()
    }
    // WARNING: Måste jag verkligen ska en klon här varje jävla gång?
    setSectionDone() {
        const {checked} = this.state
        let obj : CheckedStep = checked
        let sd = true
        Object.keys(obj).forEach(key => {
            if(!obj[parseInt(key)]) {
                sd = false
            }
        })
        this.setState({sectionDone: sd, isCollapsed: sd})
        this.props.onSectionDone(this.props.id, sd)
    }
    titleOnpress() {
        this.setState({isCollapsed: !this.state.isCollapsed})
    }
 
    render() {
        const {steps, amount, minAmount} = this.props
        const {sectionDone} = this.state
        const stepsClone : Step[] = JSON.parse(JSON.stringify(steps))
        const obj : CheckedStep = this.state.checked
        let i = 0
        return (
            <View style={styles.container}>
                <Touchable onPress={() => this.titleOnpress()}>
                    <View style={[styles.titleContainer, sectionDone ? styles.borderDone : styles.border]}>
                        <MfText style={[styles.title, sectionDone ? styles.sectionDoneColors : null]} textStyle={TEXT_STYLES.HEADER}>{this.props.title}</MfText>
                        {sectionDone ? <Image style={styles.icon} source={check as ImageSourcePropType} /> : null }
                    </View> 
                </Touchable>
                
                <MfCollapsible collapsed={this.state.isCollapsed}>
                    {stepsClone.map(step => {
                        i++
                        return (<RecipeStep 
                            onPress={() => this.onPress(step.id)} 
                            key={step.id} checked={obj[step.id]} 
                            style={styles.step}
                            customComponent={this.convertIngredients(step, amount, minAmount)} />)
                    })}
                </MfCollapsible>
            </View>
        )
    }
}

export default RecipeSection
