import { Action, ActionCreator, Dispatch} from 'redux'
import { Ingredient, IngredientBase } from '../../model'
import { Types } from './types';


export interface IAddAction extends Action {
    type: Types.ADD_TO_PANTRY
    ingredients: IngredientBase[]
}

export interface IRemoveAction extends Action {
    type: Types.REMOVE_FROM_PANTRY
    id: number
}

export interface IClearAction extends Action {
    type: Types.CLEAR_PANTRY
}

export type IPantryAction = IAddAction | IRemoveAction | IClearAction

const addIngredientsToPantry: ActionCreator<IAddAction> = (ingredients: IngredientBase[]) => {
    return {
        type: Types.ADD_TO_PANTRY,
        ingredients: ingredients
    }
}

export function addToPantry(ingredients: IngredientBase[]) {
    return async (dispatch: Dispatch) => {
        dispatch(addIngredientsToPantry(ingredients))
    }
}

const removeIngredientFromPantry: ActionCreator<IRemoveAction> = (id: number) => {
    return {
        type: Types.REMOVE_FROM_PANTRY,
        id: id
    }
}

export const clearPantry: ActionCreator<IClearAction> = () => {
    return {
        type: Types.CLEAR_PANTRY
    }
}

export function removeFromPantry(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(removeIngredientFromPantry(id))
    }
}
