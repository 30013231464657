import {StyleSheet, Platform} from 'react-native'
import { NAVBAR_SIZE, CARD_SIZE, WINDOW_SIZE, CARD_IMAGE_SIZE, PRIMARY_THEME_COLORS } from '../../../../../config/style';

export default StyleSheet.create({
    container: {
        flex: 1,
        
    },
    header: {
        position: 'absolute',
        top: Platform.OS === 'web' ? 0 : 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        overflow: 'hidden',
    },
    tablet: {
        width: 100
    },
    items: {
        height: 200,
        margin: 20,
        backgroundColor: 'pink'
    },
    image: {
        flex: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: undefined,
        height: undefined,
        resizeMode: 'cover',
    },
    fill: {
        flex: 1
    },
    scrollContent: {

    },
    actionContainer: {
        height: CARD_SIZE.height - CARD_IMAGE_SIZE.height,
        // width: WINDOW_SIZE.width,
        flexDirection: 'row',
        // backgroundColor: PRIMARY_THEME_COLORS.backgroundLight,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        
    },
    actionIcon: {
        height: 35,
        width: 35,
    },
})