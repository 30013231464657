import React from 'react'
import {Component} from 'react'
import { Platform } from 'react-native' 
import {IScreen, Screen} from '../../../interface'
import RecipeOverviewContainer from './recipe_overview_container';
import { Routes } from '../../../navigation/stacks';
import { NavigationScreenProps } from 'react-navigation';
import InformationButton from '../../../components/nav_buttons/information_button/'


class RecipeOverview extends Screen {

    state = {
        showModal: false,
        navTitle: Platform.OS !== 'web' ? this.props.navigation.getParam('title') : this.props.webNavigation.title
    }
    static navigationOptions = ({navigation}: NavigationScreenProps) => {
        return {
            headerRight: <InformationButton onPress={navigation.getParam('buttonOnPress')} />,
            headerTitle: navigation.getParam('title')
        }
    }

    componentDidMount() {
        if (Platform.OS !== 'web') {
            this.props.navigation.setParams({buttonOnPress: this.toogleModal})
        } else {
            this.props.webNavigation.setHeaderRight(
                <InformationButton onPress={() => this.toogleModal()} />
            )
        }
        
    }
    toogleModal = () => {
        this.setState({showModal: true})
    }

    onModalClose = () => {
        this.setState({showModal: false})
    }
    navigate() {
        if (Platform.OS === 'web') {
            this.props.webNavigate(Routes.RECIPE_INSTRUCTIONS, this.state.navTitle)
        } else {
            this.props.navigation.navigate(Routes.RECIPE_INSTRUCTIONS, {title: this.state.navTitle})
        }
    }

    render() {
        return (
            <RecipeOverviewContainer setTitle={() => {}} showModal={this.state.showModal} onModalClose={this.onModalClose} onNavigate={() => this.navigate()} />
        )
    }
}

export default RecipeOverview
