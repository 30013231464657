import React from 'react'
import {Component} from 'react'
import {View, Text} from 'react-native'
import {Route, Switch, Router} from 'react-router-dom'
import history from './history'
import NavBar from './NavBar'
import { IStack, Routes } from '../../app/navigation/stacks';
import Drawer from './WebDrawer'
import { IState, ICategoryState, IRecipeState } from '../../app/model/state';
import { connect } from 'react-redux'
import queryString from 'query-string'
import { setCategory, getCategories } from '../../app/redux/actions/categories';
import { setRecipe, getRecipes } from '../../app/redux/actions/recipes'
import { IScreen } from '../../app/interface';



interface IWebRouter {
    stacks: {
        [key: string]: {
            screen: IStack,
            navigationOptions: {
                title: string
            },
            rootRoute: string
        }
    }
}

interface ReduxProps {
    dataLoaded: boolean,
    categories: ICategoryState,
    recipes: IRecipeState
}

interface DispatchProps {
    setCurrentCategory: Function,
    setCurrentRecipe: Function,
    getRecipes: () => Promise<any>,
    getCategories: () => Promise<any>
}

export interface ReactProps extends IWebRouter {}

type Props = ReduxProps & ReactProps & DispatchProps

interface State {}

class WebRouter extends Component<Props, State> {
    state = {
        title: 'MatFröjd Start',
        isRoot: true,
        refreshState: false,
        drawerOpen: false,
        titleStack: ['Matfröjd Start'],
        dataSet: false,
        headerRight: <View></View>,
        firstRender: true,
        navBarBackgroundColor: undefined
    }

    constructor(props: Props) {
        super(props)
    }

    componentDidMount() {
        window.onpopstate = (event) => {
            this.setState({
                refreshState: true,
            })
          }
          this.setCurrentState()
    }

    getRecipeName(id: number) {
        const r = this.props.recipes.recipes.find(re => re.id === id)
        return r ? r.name : ''
    }

    getCategoryName(id: number) {
        const c = this.props.categories.categories.find(ca => ca.id === id) 
        return c ? c.name : ''
    }

    // WARNING: hur hantera om id i query inte existerar i state? Här eller på komponentnivå?    
    // Mer felhantering här!
    //WARNING: ta bort await-grejerna när vi kör local storage
    async setCurrentState() {
        const query = queryString.parse(history.location.search)
        if (Object.keys(query).length !== 0) {
            if (query.id) {
                await this.props.getRecipes()
                await this.props.getCategories()
                let id = parseInt(query.id as string)
                switch (history.location.pathname) {
                    case this.findRoute(Routes.RECIPE_LIST).url:
                        this.props.setCurrentCategory(id)
                        this.setState({title: this.getCategoryName(id)})
                        break
                    case this.findRoute(Routes.RECIPE_OVERVIEW).url:
                        this.props.setCurrentRecipe(id)
                        this.props.setCurrentCategory(id)
                        this.setState({title: this.getRecipeName(id)})
                        break
                    case this.findRoute(Routes.RECIPE_INSTRUCTIONS).url:
                        this.props.setCurrentRecipe(id)
                        this.setState({title: this.getRecipeName(id)})
                        break
                    default:
                        break
                }
            } else {
                history.push('/')
            }
        } 
        this.setState({dataSet: true})
    }

    createQuery(route: string) {
        let query = '?id='
        switch (route) {
            case Routes.RECIPE_LIST:
                query += this.props.categories.currentCategory != null ? this.props.categories.currentCategory.id : ''
                break
            case Routes.RECIPE_OVERVIEW:
                return query + (this.props.recipes.currentRecipe ? this.props.recipes.currentRecipe.id : '')
            case Routes.RECIPE_INSTRUCTIONS:
                return query + (this.props.recipes.currentRecipe ? this.props.recipes.currentRecipe.id : '')
            default:
                return ''
        }
        return query
    }
    
    navigate(route: string, nextTitle: string) {
        const {titleStack} = this.state 
        const routeDetails = this.findRoute(route)
        titleStack.push(nextTitle)
        this.setState({
            isRoot: routeDetails.url === '/',
            title: titleStack[titleStack.length-1],
            firstRender: false
        })
        history.push(routeDetails.url+this.createQuery(route))
    }

    getStackLinks() {
        let links = []
        const {stacks} = this.props
        for (let stack in stacks) {
            const obj = {
                stackName: stack,
                title: stacks[stack].navigationOptions.title,
                route: stacks[stack].rootRoute
            }
            links.push(obj)
        }
        return links
    }

    findRoute(route: string) {
        const {stacks} = this.props
        const routeDetails = {
            url: '/',
            title: 'MatFröjd Start'
        }
        for (let stack in stacks) {
            for (let screen in stacks[stack].screen) {
                if (screen === route && route != Routes.START) {
                    routeDetails.url = stacks[stack].screen[screen].navigationOptions.webRoute
                    routeDetails.title = stacks[stack].screen[screen].navigationOptions.title
                    return routeDetails
                }
            }
        }
        return routeDetails
    }

    historyBack() {
        console.log('router history back')
        const {titleStack} = this.state
        titleStack.pop()
        this.setState({title: titleStack[titleStack.length-1]})
        if (history.location.pathname === '/') {
            this.setState({isRoot: true})
        } else {
            history.goBack()
        }
    }

    toggleDrawer() {
        this.setState({drawerOpen: !this.state.drawerOpen})
    }

    onDrawerClose(route: string) {
        this.setState({drawerOpen: false})
        history.push(route)
    }

    setHeaderRight(e: JSX.Element) {
        this.setState({headerRight: e})
    }

    getHeaderRightAction() {
        console.log('get header right action')
    }

    setNavBarBackgroundColor(bg: string) {
        this.setState({navBarBackgroundColor: bg})
    }

    render() {
        const {stacks} = this.props
        const isRoot = history.location.pathname === '/'
        const {firstRender} = this.state
        const links = this.getStackLinks()
        const webNavigation = {
            setHeaderRight: (e: JSX.Element) => this.setHeaderRight(e),
            actionCallback: () => this.getHeaderRightAction(),
            title: this.state.title,
            setNavBarBackgroundColor: (bg: string) => this.setNavBarBackgroundColor(bg),
            goBack: () => this.historyBack()
        }
        return (
            <Router history={history}>
                {(!this.props.dataLoaded || !this.state.dataSet) ? 
                <View><Text>Laddar...</Text></View>
                :
                <View style={{height: '100%', width: '100%', overflow: 'hidden'}}>
                    <NavBar 
                        isRoot={isRoot} 
                        showHome={firstRender && !isRoot}
                        onPress={firstRender ? () => this.navigate(Routes.START, 'Matfröjd') : isRoot ? () => this.toggleDrawer() : () => this.historyBack()} 
                        headerRight={this.state.headerRight}
                        backgroundColor={this.state.navBarBackgroundColor}
                        title={this.state.title} />
                        
                        {/* <View style={{height: 60}}></View> */}
                        <Switch>
                        
                            {Object.keys(stacks).map((routes: string) => {
                                return (
                                    Object.keys(stacks[routes].screen).map((route: string) => {   
                                        // WARNING: Hur typa Screen?
                                        const Screen = stacks[routes].screen[route].screen
                                        if (stacks[routes].screen[route].isRoot) {
                                            return (
                                                <Route 
                                                    key={stacks[routes].screen[route].navigationOptions.webRoute} 
                                                    render={() => <Screen webNavigation={webNavigation} webNavigate={(route: string, nextTitle: string) => this.navigate(route, nextTitle)}/>}
                                                    exact path="/" 
                                                />
                                            )
                                        } else {
                                            return (
                                                <Route 
                                                    key={stacks[routes].screen[route].navigationOptions.webRoute} 
                                                    render={() => <Screen webNavigation={webNavigation} webNavigate={(route: string, nextTitle: string) => this.navigate(route, nextTitle)}/>}
                                                    path={stacks[routes].screen[route].navigationOptions.webRoute} 
                                                />
                                            )
                                        }
                                    })
                                )
                        })}
                        
                        </Switch>
                        
                    <Drawer 
                        open={this.state.drawerOpen} 
                        onNavigate={(route: string) => this.onDrawerClose(route)} 
                        onClose={() => this.toggleDrawer()} 
                        links={links} />
                </View>
                }
            </Router>
        )
    }
}

const mapStateToProps =  (state: IState) => {
    return {
        dataLoaded: state.recipeState.dataLoaded && state.categoryState.dataLoaded,
        categories: state.categoryState,
        recipes: state.recipeState
    } as ReduxProps
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setCurrentCategory: (id: number) => dispatch(setCategory(id)),
        setCurrentRecipe: (id: number) => dispatch(setRecipe(id)),
        getRecipes: () => dispatch(getRecipes()),
        getCategories: () => dispatch(getCategories())
        
    }
}

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(mapStateToProps, mapDispatchToProps)(WebRouter)