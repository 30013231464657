import {StyleSheet} from 'react-native'
import { WINDOW_SIZE } from '../../../../config/style';



export default StyleSheet.create({
    container: {
        alignItems: 'center'
    },
    list: {
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%'
    },
    filterButtonC: {
        marginBottom: 20,
        position: 'absolute',
        bottom: 0,
        // width: WINDOW_SIZE.width,
        alignItems: 'center'
    },
    
    emptyResult: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 200
    }
    
})