import React from 'react'
import {Component} from 'react'
import { connect } from 'react-redux'
import { View, ScrollView, Platform } from 'react-native';
import { IState } from '../../../model/state';
import { IngredientBase, Recipe } from '../../../model';
import { IRecipeState } from '../../../model/state';
import { addToPantry, removeFromPantry, clearPantry, IAddAction, IRemoveAction, IClearAction, getIngredients, setRecipe } from '../../../redux/actions'
import MfContainer from '../../../components/mf_container';
import { backgrounds } from '../../../components/mf_container/mf_container';
import styles from './styles'
import Button, { BUTTON_VARIANTS } from '../../../components/button/button';
import AddToPantryModal from './add_to_pantry_modal';
import { getText } from '../../../helpers/text/translation';
import { getDate } from '../../../helpers/date';
import FindRecipeModal from './find_recipe_modal';
import MfText, { TEXT_STYLES } from '../../../components/mf_text';
import Alert from '../../../components/alert';
import Chip from '../../../components/chip';


interface ReduxProps {
    pantryIngredients: IngredientBase[]
    allIngredients: IngredientBase[]
    recipeState: IRecipeState
}

export interface ReactProps {
    // triggerShare: boolean,
    // onShareRespond: Function,
    setTitle: Function,
    onNavigate: Function,
    // showClearModal: boolean
}

interface DispatchProps {
    setCurrentRecipe: Function,
    addToPantry: (ingredients: IngredientBase[]) => IAddAction,
    removeFromPantry: (id: number) => IRemoveAction,
    clearPantry: () => IClearAction,
    getIngredients: () => Promise<any>
}

interface ISortedIngredients {
    [key: string] : {
        categoryName: string,
        categoryId: number,
        ingredients: IngredientBase[]
    }
}

//Number of ingredients from pantry that matches a recipe
interface FilterMap {
    [recipeId: number]: {
        numMatched: number
        numIngredients: number
    }
}

export interface FilterResult {
    recipes: Recipe[]
    filterMap: FilterMap
}

type Props = ReduxProps & ReactProps & DispatchProps

interface State {}

type FilterType = 'ABSOLUTE' | 'RATIO'

class PantryContainer extends Component<Props, State> {
    private filterType: FilterType = 'ABSOLUTE'

    //Percentage of ingredients needed for find recipe when filterType is RATIO
    private threshold = 0.66

    //Number of keywords matching to show a recipe when filterType is ABSOLUTE
    private absoluteThreshold = 1

    state = {
        filterIngredients: this.props.pantryIngredients,
        showAddModal: false,
        showFindRecipeModal: false,
        showClearModal: false
    }

    constructor(props: Props) {
        super(props)
        this.props.getIngredients()
    }

    onAdd = async (addIngredients: {[key: string]: IngredientBase}) => {
        this.props.addToPantry(Object.values(addIngredients))
        this.setState({showAddModal: false})
    }
    

    async removeIngredient(id: number, catName: string) {
        await this.props.removeFromPantry(id)
    }

    getAvailableIngredients() {
        const ai : IngredientBase[] = []
        const {allIngredients, pantryIngredients} = this.props
        allIngredients.forEach(ing => {
           let add = true
           pantryIngredients.forEach(ping => {
               if (ping.id == ing.id) {
                  add = false 
                  return
               } 
           })
           if (add) ai.push(ing)
        })
        return ai.sort((a, b) => a.name.localeCompare(b.name))
    }

    onShareReturn = () => {
        // this.props.onShareRespond()
    }

    getShareMessage(sortedIngredients: ISortedIngredients) {
        const lineBreak = Platform.OS === 'web' ? '%0D' : '\n'
        let message = `${getText('Skafferi')} ${getDate()}${lineBreak} ${lineBreak}`  
        Object.keys(sortedIngredients).forEach(category => {
            message += `${sortedIngredients[category].categoryName}:${lineBreak}`

            sortedIngredients[category].ingredients.forEach(ing => {
                message += `* ${ing.name}${lineBreak}`
            })
            message += `${lineBreak}`
        })
        return message
        
    }

    filterRecipes(ingredients: IngredientBase[], recipes: Recipe[]): FilterMap {
        const filterMap: FilterMap = {} 
        //Init the filterMap with 0 matched ingredients, and number of total ingredients
        //Ignore alternatives for now
        recipes.forEach(r => {  
            filterMap[r.id] = {
                numIngredients: r.sections.filter( s => 
                    !s.isAlternative).map( s => 
                        s.ingredients.length).reduce((i1, i2) => i1 + i2, 0),
                numMatched: 0,
            }
        })
        const ing_ids = ingredients.map(i => i.id)
        recipes.forEach(recipe => {
            recipe.sections.forEach(section => {
                if (section.isAlternative) return
                section.ingredients.forEach(ingredient => {
                    if (ing_ids.includes(ingredient.id)) {
                        filterMap[recipe.id].numMatched += 1
                    }
                })
            })
        })
        return filterMap
    }

    getRecipesForFind(ingredients: IngredientBase[], recipes: Recipe[]): FilterResult {
        const filterMap = this.filterRecipes(ingredients, recipes)
        const filteredRecipes = this.filterType == 'RATIO' ? 
            recipes.filter(r => filterMap[r.id].numMatched/filterMap[r.id].numIngredients >= this.threshold) :
            recipes.filter(r => filterMap[r.id].numMatched >= this.absoluteThreshold)
        //If threshold is less than 1, we need to sort here
        const sortedFilteredRecipes = filteredRecipes.sort((a: Recipe, b: Recipe) => 
            filterMap[b.id].numMatched/filterMap[b.id].numIngredients - filterMap[a.id].numMatched/filterMap[a.id].numIngredients
        )
        return {
            recipes: sortedFilteredRecipes,
            filterMap: filterMap
        }
    }

    async recipeSelected(id: number, name: string) {
        await this.props.setCurrentRecipe(id)
        this.setState({showFindRecipeModal: false})
        this.props.setTitle(name)
        this.props.onNavigate()        
    }

    clear() {
       this.setState({showClearModal: true}) 
    }

    onCloseClearModal() {
       this.setState({showClearModal: false}) 
    }

    onClearAccept() {
        this.props.clearPantry()
    }

    renderEmptyPantryText() {
        return (
            <View style={styles.emptyContainer} >
                <MfText textStyle={TEXT_STYLES.SUB_HEADER}>{getText("Vad vill du laga mat med?")}</MfText>
                <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{getText("Lägg till ingredienser genom att trycka på +")}</MfText>
            </View>
        )
    }

    render() {
        const {showAddModal, showFindRecipeModal} = this.state
        const {recipeState, pantryIngredients} = this.props
        return (
            <MfContainer backgroundImage={backgrounds.PANTRY}>
                <Alert 
                    title={getText('Rensa')} 
                    content={getText('Vill du verkligen rensa hela skafferiet?')}
                    onClose={() => this.onCloseClearModal()}
                    onAccept={() => this.onClearAccept()}
                    onDenied={() => this.onCloseClearModal()}
                    visible={this.state.showClearModal}
                    />
                {/* {triggerShare ? <MfShare message={this.getShareMessage(sortedIngredients)} onReturn={this.onShareReturn}/> : null} */}
                <View>
                { pantryIngredients.length > 0 ?
                    <ScrollView contentContainerStyle={styles.container}>
                        {pantryIngredients.map(i => 
                            <Chip key={i.id} term={i.name} onPress={() => this.removeIngredient(i.id, i.categoryName)} />
                        )}
                    </ScrollView>
                    :
                    this.renderEmptyPantryText()
                }
                </View>
                <View style={styles.spacer} />
                <View style={styles.bottomButtonContainer}>
                    <Button variant={BUTTON_VARIANTS.NO_RADIUS} style={styles.buttonLeft} title={getText('Rensa')} onPress={() => this.clear()} />
                    <Button style={styles.buttonRight} variant={BUTTON_VARIANTS.NO_RADIUS}  onPress={() => this.setState({showFindRecipeModal: true})} title={getText('Hitta recept')} />
                </View>
                <Button bottom={100} style={styles.actionButton} variant={BUTTON_VARIANTS.FLOATING_ACTION} onPress={() => this.setState({showAddModal: true})} title='+' />
                <AddToPantryModal visible={showAddModal} onAdd={this.onAdd} onClose={() => this.setState({showAddModal: false})} ingredients={this.getAvailableIngredients()}/>
                <FindRecipeModal 
                    visible={showFindRecipeModal} 
                    onSelected={(id: number, name: string) => this.recipeSelected(id, name)}
                    filterResult={this.getRecipesForFind(pantryIngredients, recipeState.recipes)} 
                    onClose={() => this.setState({showFindRecipeModal: false})} />
            </MfContainer>
            
        )
    }
}

const mapStateToProps = (state: IState) => {
    return { 
        pantryIngredients: state.pantryState.ingredients,
        allIngredients: state.ingredientState.ingredients,
        recipeState: state.recipeState,
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    const props: DispatchProps = { 
        setCurrentRecipe: (id: number) => dispatch(setRecipe(id)),
        addToPantry: (ingredients: IngredientBase[]) => dispatch(addToPantry(ingredients)),
        removeFromPantry: (id: number) => dispatch(removeFromPantry(id)),
        clearPantry: () => dispatch(clearPantry()),
        getIngredients: () => dispatch(getIngredients())
    } 
    return props
}
export default connect<ReduxProps,  DispatchProps, ReactProps, IState>( mapStateToProps, mapDispatchToProps)(PantryContainer);
