import { WINDOW_SIZE, NAVBAR_SIZE } from "./size";
import { Platform } from "react-native";
console.log(WINDOW_SIZE.height)
export const WEB_SCROLLVIEW_STYLE = {
    height: `${WINDOW_SIZE.height-NAVBAR_SIZE.height}px`,
    // paddingBottom: 60
}

console.log(WEB_SCROLLVIEW_STYLE)

export const IS_WEB = Platform.OS === 'web'