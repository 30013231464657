import React from 'react'
import {Component} from 'react'
import styles from './styles'
import {View, Animated, ScrollView, Image, ImageSourcePropType} from 'react-native'
import Touchable from '../../../../../components/touchable'
import { CARD_SIZE, CARD_IMAGE_SIZE } from '../../../../../config/style';
import { IS_TABLET } from '../../../../../helpers/device';
import MfImage from '../../../../../components/mf_image';
import ImageLoader from '../../../../../components/image_loader';
import { TOUCHEABLE_TYPES } from '../../../../../components/touchable/touchable';
import { IS_WEB, WEB_SCROLLVIEW_STYLE } from '../../../../../config/style/web_helper'

const AnimatedMfImage = Animated.createAnimatedComponent(MfImage)

type cardActions = {
    icon: {uri: string},
    onPress: Function,
    component?: JSX.Element
}

interface IAnimatedScroll {
    image: {uri: string},
    actions?: cardActions[], 
    disableScroll?: boolean,
    tablet: boolean,
    customHeight?: number
}


class AnimatedScroll extends Component<IAnimatedScroll> {
    state = {
        scrollY: new Animated.Value(0),
    }


    render() {
        const {customHeight} = this.props

        const HEADER_MAX_HEIGHT: number = IS_TABLET ? CARD_SIZE.heightTablet ? CARD_SIZE.heightTablet : CARD_SIZE.height : CARD_SIZE.height
        // const HEADER_MIN_HEIGHT: number = CARD_SIZE.height - CARD_IMAGE_SIZE.height
        const HEADER_MIN_HEIGHT: number = customHeight ? 0 : CARD_SIZE.height - CARD_IMAGE_SIZE.height
        const HEADER_SCROLL_DISTANCE = customHeight ? customHeight - HEADER_MIN_HEIGHT : HEADER_MAX_HEIGHT - HEADER_MIN_HEIGHT

        
        const headerHeight = this.state.scrollY.interpolate({
            inputRange: [0, HEADER_SCROLL_DISTANCE],
            outputRange: [customHeight || HEADER_MAX_HEIGHT, HEADER_MIN_HEIGHT],
            extrapolate: 'clamp',
        })
        const opacity = this.state.scrollY.interpolate({
            inputRange: [0, HEADER_SCROLL_DISTANCE],
            outputRange: [1, 0],
            extrapolate: 'clamp',
        })
        const {actions} = this.props
        return (
            <View style={IS_WEB ? WEB_SCROLLVIEW_STYLE : {flex: 1}}>
                <ScrollView
                    scrollEventThrottle={1}
                    onScroll={Animated.event(
                        [{nativeEvent: {contentOffset: {y: this.state.scrollY}}}]
                    )}
                    >
                    <View style={{marginTop: customHeight || HEADER_MAX_HEIGHT}}>
                        {this.props.children}
                    </View>
                </ScrollView>
                
                <Animated.View style={[styles.header, {height: headerHeight}]}>
                    <AnimatedMfImage style={[styles.image, {opacity: opacity}]} source={this.props.image.uri} loader={<ImageLoader />}/>
                    {actions? 
                    <View style={styles.actionContainer}>
                        {actions.map((action, index) => {
                            if (action.component) {
                                return (
                                    <View key={index}>{action.component}</View>
                                )
                            } else {
                                return (
                                    <Touchable type={TOUCHEABLE_TYPES.OPACITY} key={index} onPress={() => action.onPress()}>
                                        <Image style={styles.actionIcon} source={action.icon.uri as ImageSourcePropType} />
                                    </Touchable>
                                )
                            }
                        })} 
                    </View>
                    : null}
                </Animated.View>
            </View>
        )
    }
}

export default AnimatedScroll