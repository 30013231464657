import React from 'react'
import {Component} from 'react'
import styles from './styles'
import {View, SafeAreaView, Image, ImageSourcePropType, Text} from 'react-native'
import MfModal, { animationTypes } from '../../../../../components/mf_modal';
import MfText, { TEXT_STYLES } from '../../../../../components/mf_text';
import { getText } from '../../../../../helpers/text/translation';
import Close from '../../../../../../assets/ui/close.png'
import Touchable from '../../../../../components/touchable';
import { Section } from '../../../../../model';
import Button from '../../../../../components/button/button';
import MfCollapsible from '../../../../../components/mf_collapsible';
import check from '../../../../../../assets/mf_ui/bockav-ikon-c80078.png'
import { PRIMARY_THEME_COLORS } from '../../../../../config/style';

export interface IAlternatives {
    [key:number]: {
        title: string,
        alteredSection: number,
        alternatives: [Section],
    }
}

interface IAdjustModal {
    sections: IAlternatives,
    visible: boolean,
    onClose: Function,
    itemOnPress: Function,
    buttonOnPress: Function
}
class AdjustModal extends Component<IAdjustModal> {
    state = {
        showModal: this.props.visible,
        collapsed: this.setCollapsed()
    }

    setCollapsed() {
        interface coll {[key:number]: boolean}
        const c : coll = {}
        const {sections} = this.props
        Object.keys(sections).forEach(section => {
            c[parseInt(section)] = true
        })
        return c
    }
    onClose() {
        this.props.onClose()
    }
    itemOnPress(id: number, alt: number) {
        this.props.itemOnPress(id, alt)
    }
    buttonOnPress() {
        this.props.buttonOnPress()
    }
    listHeaderPressed(id: number) {
        const {collapsed} = this.state
        this.setState({collapsed: Object.assign({}, collapsed, {[id]: !collapsed[id]})})
    }

    render() {
        const {sections} = this.props
        return (
                <MfModal visibile={this.props.visible} transparent={false} animationType={animationTypes.SLIDE} onClose={() => this.onClose()}>
                    <SafeAreaView style={styles.container}>
                        <View style={styles.header}>
                            <Touchable onPress={() => this.onClose()}>
                                <Image style={styles.icon} source={Close as ImageSourcePropType} />
                            </Touchable>
                            <MfText style={styles.headerText} textStyle={TEXT_STYLES.HEADER}>{getText('Välj tillbehör')}</MfText>
                            <View style={styles.headerRight}></View>
                        </View>
                        <View style={styles.contentContainer}>
                        {Object.keys(sections).map(section => {
                            const id = parseInt(section)
                            const s = sections[id]
                            const mainChecked = s.alteredSection === id
                            return (
                                <View key={s.title} style={styles.listContainer}>
                                    {/* <Touchable onPress={() => this.listHeaderPressed(id)}> */}
                                        <View style={styles.listHeader}>
                                            <MfText textStyle={TEXT_STYLES.SUB_HEADER}>{`${getText('Alternativ för ')}${s.title}:`}</MfText>
                                        </View>
                                    {/* </Touchable> */}
                                    {/* <MfCollapsible collapsed={this.state.collapsed[id]}> */}
                                        <Touchable onPress={() => this.itemOnPress(id, id)}>
                                            <View style={[styles.listItem, mainChecked ? styles.listItemHighlight : null]}>
                                                <MfText style={mainChecked ? styles.textChecked : styles.text} textStyle={TEXT_STYLES.PLAIN_TEXT}>{s.title}</MfText>
                                                {mainChecked ? <Image style={styles.checkBox} source={check as ImageSourcePropType} /> : null }
                                            </View>
                                        </Touchable>
                                    {s.alternatives.map(alt => {
                                        const checked = s.alteredSection === alt.id
                                        return (
                                            <Touchable key={alt.id} onPress={() => this.itemOnPress(id, alt.id)}>
                                                <View style={[styles.listItem, checked ? styles.listItemHighlight : null]}>
                                                    <MfText style={checked ? styles.textChecked : styles.text} textStyle={TEXT_STYLES.PLAIN_TEXT}>{alt.title}</MfText>
                                                    {checked ? <Image style={styles.checkBox} source={check as ImageSourcePropType} /> : null }
                                                </View>
                                            </Touchable>
                                        )
                                    })}
                                    {/* </MfCollapsible> */}
                                </View>
                            )
                        })}
                        </View>
                        <View style={styles.footer}>
                            <Button style={styles.button} title={getText('Klar')} onPress={() => this.buttonOnPress()} />
                        </View>
                    </SafeAreaView>
                </MfModal>
        )
    }
}

export default AdjustModal