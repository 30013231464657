import {StyleSheet, Platform} from 'react-native'
import {CARD_SIZE, CARD_IMAGE_SIZE, PRIMARY_THEME_COLORS, TEXT_COLORS, CARD_HEADER_SIZE} from '../../config/style'

export default StyleSheet.create({
    container: {
        backgroundColor: PRIMARY_THEME_COLORS.backgroundLight,
        ...Platform.select({
            web: {
                boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.3)'
            },
            android: {
                shadowColor: PRIMARY_THEME_COLORS.shadowColor,
                shadowOpacity: 0.6,
                shadowRadius: 1,
                shadowOffset: {width: 0, height: 2},
                elevation: 3,
            },
            default: {
                shadowColor: PRIMARY_THEME_COLORS.shadowColor,
                shadowOpacity: 0.6,
                shadowRadius: 1,
                shadowOffset: {width: 0, height: 2},
            }
        })
    },
    containerStandard: {
        width: CARD_SIZE.width,
        minHeight: CARD_SIZE.height,
        maxWidth: CARD_SIZE.maxWidth
    },
    containerSmall: {
        height: CARD_SIZE.smallHeight,
        width: CARD_SIZE.smallWidth,
        maxWidth: CARD_SIZE.maxWidth,
        flexDirection: 'row'
    },
    margin: {
        marginTop: CARD_SIZE.marginTop,
    },
    imageContainer: {
        width: CARD_IMAGE_SIZE.width,
        height: CARD_IMAGE_SIZE.height,
        maxWidth: CARD_IMAGE_SIZE.maxWidth
    },
    imageContainerSMall: {
        width: CARD_IMAGE_SIZE.smallWidth,
        height: CARD_IMAGE_SIZE.smallHeight
    },
    headerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center'
    },
    headerText: {
        textAlign: 'center',
    },
    headerTextSmall: {
        fontSize: 13,
    },
    image: {
        flex: 1,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: undefined,
        height: undefined,
        resizeMode: 'cover'
    },
    badge: {
        borderRadius: 100,
        backgroundColor: PRIMARY_THEME_COLORS.green,
        position: 'absolute',
        right: -8,
        top: -8,
        zIndex: 100,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 16,
        paddingBottom: 16
    },
    badgeText: {
        color: 'white'
    }

})