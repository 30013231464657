import React from 'react'
import {Component} from 'react'
import {IContainer} from '../../../../interface'
import {View, Platform, Image, ImageSourcePropType, ScrollView} from 'react-native'
import { Recipe, Section, Ingredient, Nutrition } from '../../../../model';
import { IState, IAlteredSections } from '../../../../model/state';
import { connect } from 'react-redux';
import RecipeSection from '../../../../components/recipe_section';
import MfText, { TEXT_STYLES } from '../../../../components/mf_text';
import styles from './styles';
import { getText } from '../../../../helpers/text/translation';
import Button, { BUTTON_VARIANTS } from '../../../../components/button/button';
import InfoModal from '../../recipe_overview/recipe_overview_container/info_modal'
import ListItem from '../../../../components/list_item';
import FavoriteAnimation from '../../../../components/favorite_animation';
import { addToFavorites, removeFromFavorites, setCurrentSectionsDone } from '../../../../redux/actions';
import { IS_TABLET } from '../../../../helpers/device';
import AnimatedScroll from '../../recipe_overview/recipe_overview_container/animated_scroll';
import QuantityHandler from '../../../../helpers/quantity_handler'
import Logo from '../../../../../assets/logos/icon_clean.png'
import MfImage from '../../../../components/mf_image';
import ImageLoader from '../../../../components/image_loader';
import { activateKeepAwake, deactivateKeepAwake } from 'expo-keep-awake';
import { WEB_SCROLLVIEW_STYLE, IS_WEB } from '../../../../config/style/web_helper';
import { CheckedStep } from '../../../../components/recipe_section/recipe_section';
import NutritionModal from '../../recipe_overview/recipe_overview_container/nutrition_modal';
import { calculateNutritions } from '../../../../helpers/nutritions_handlers';

interface ReduxProps { 
    currentRecipe: Recipe, 
    currentAmount: number, 
    currentAlteredSections: IAlteredSections,
    favorites: number[],
    nutritions: Nutrition[],
    currentSectionsDone: CheckedSections
    }
export interface ReactProps extends IContainer {
    onModalClose: Function,
    showModal: boolean
}
interface DispatchProps {
    addToFavorites: (id: number) => void,
    removeFromFavorites: (id: number) => void,
    setSectionsDone: (s: CheckedSections) => void
}
type Props = ReduxProps & ReactProps & DispatchProps
interface State {}

interface ing {[key:string] : Ingredient}

export interface CheckedSections {[key:number]:CheckedStep}

class RecipeInstructionsContainer extends Component<Props, State> {
    state = {
        sections: this.getSections(),
        sectionsDone: false,
        currentSectionsDone: this.props.currentSectionsDone,
        ingredientCardCollapsed: false,
        showNutritionModal: false
    }

    componentDidMount() {
        
        activateKeepAwake()
    }
    componentWillUnmount() {
        deactivateKeepAwake()
    }

    setSectionDone = (id: number, done: boolean) => {
        // this.state.sections[id] = done
        this.setState({sections: {...this.state.sections, [id]: done}}, () => this.getSectionsDone())
        // this.props.setSectionsDone(this.state.sections)
    }

    
    onStepDone = (steps: CheckedStep, id: number) => {
        this.props.setSectionsDone({...this.props.currentSectionsDone, [id]:steps})
    }
    getSectionsDone() {
        const {sections} = this.state
        let sectionsDone = true
        Object.keys(sections).forEach(key => {
            if (!sections[parseInt(key)]) {
                sectionsDone = false
            }
        })
        this.setState({sectionsDone: sectionsDone})
    }

    recipeIsFavorite() {
        const {favorites, currentRecipe} = this.props
        return favorites.indexOf(currentRecipe.id) > -1
    }

    getSections() {
        const {sections} = this.props.currentRecipe
        const {currentAlteredSections, currentSectionsDone} = this.props
        const s: {[key:number]: boolean} = {}
        sections.forEach(section => {
            if (!(section.id in currentAlteredSections) && !section.isAlternative) {
                // s[section.id] = section.id in currentSectionsDone ? currentSectionsDone[section.id] : false
                s[section.id] = false
            } else if (section.isAlternative && currentAlteredSections[section.altId] === section.id) {
                // s[section.id] = section.id in currentSectionsDone ? currentSectionsDone[section.id] : false
                s[section.id] = false
            }
        })
        return s
    }
    setIngredientAmount() {

    }

    getIngredients(didMount: boolean) {
        const result : ing  = {}
        const { sections } = this.props.currentRecipe
        const {currentAlteredSections, currentAmount, currentRecipe} = this.props
        const ai: number[] = []
        sections.forEach((section, index) => {
            if (!(section.id in currentAlteredSections) && !section.isAlternative) {
                section.ingredients.forEach((ingredient) => {
                    const id = ingredient.id
                    if (id in result) { 
                        const i2 = result[id]
                        const comb = QuantityHandler.combineMeasurements(i2.quantity, i2.measure, ingredient.quantity, ingredient.measure)
                        if (comb) {
                            result[id].quantity = comb.q
                            if (i2.measure != comb.m) {
                                result[id].measure = ingredient.measure
                                result[id].measureFull = ingredient.measureFull
                                result[id].measurePlural = ingredient.measurePlural
                                result[id].measureFullPlural = ingredient.measureFullPlural
                            }
                        }
                    } else {
                        result[id] = JSON.parse(JSON.stringify(ingredient))
                        const order = index + '' + result[id].sortOrder
                        result[id].sortOrder = parseInt(order)
                    }
                })
            } else {
                const alteredSection = sections.find((a) => a.id === currentAlteredSections[section.id])
                if (typeof alteredSection === typeof section && alteredSection !== undefined)  {
                    alteredSection.ingredients.forEach((ingredient) => {
                        const id = ingredient.id
                        if (id in result) { 
                            const i2 = result[id]
                            const comb = QuantityHandler.combineMeasurements(i2.quantity, i2.measure, ingredient.quantity, ingredient.measure)
                            if (comb) {
                                result[id].quantity = comb.q
                                if (i2.measure != comb.m) {
                                    result[id].measure = ingredient.measure
                                    result[id].measureFull = ingredient.measureFull
                                    result[id].measurePlural = ingredient.measurePlural
                                    result[id].measureFullPlural = ingredient.measureFullPlural
                                }
                            }
                        } else {
                            result[id] = JSON.parse(JSON.stringify(ingredient))
                            const order = index + '' + result[id].sortOrder
                            result[id].sortOrder = parseInt(order)
                        }
                        ai.push(id)
                    })
                }
            }
        })
        if (didMount) {
            // this.setState({alteredIngredients: ai})
        }
        return result
    }

    getTips() {
        const {currentRecipe} = this.props
        return currentRecipe.tips
    }

    collapseIngredients() {
        this.setState({ingredientCardCollapsed: !this.state.ingredientCardCollapsed})
    }

    renderInstructions() {
        const {currentRecipe, currentAlteredSections, currentAmount} = this.props
        return (
            <View style={styles.contentContainer}>
                <MfText textStyle={TEXT_STYLES.BOLD_PLAIN}>{getText('Recept för')} {currentAmount} {currentRecipe.servingsLabelSingular}</MfText>
                {currentRecipe.generalInstructionText !== null &&
                    <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{currentRecipe.generalInstructionText}</MfText>
                }
                
                {/* <MfText textStyle={TEXT_STYLES.SUB_HEADER}>{getText('Gör så här')}</MfText> */}
                {currentRecipe.sections.map(section => {
                    if (!(section.id in currentAlteredSections) && !section.isAlternative) {
                        return <RecipeSection id={section.id} 
                            onSectionDone={this.setSectionDone} 
                            doneSteps={section.id in this.props.currentSectionsDone ? this.props.currentSectionsDone[section.id] : undefined}
                            onStepDone={this.onStepDone}
                            amount={currentAmount !== 0 ? currentAmount : currentRecipe.minServings}  
                            title={section.title} 
                            key={section.id} 
                            minAmount={currentRecipe.minServings}
                            steps={section.steps} />
                    } else if (section.isAlternative && currentAlteredSections[section.altId] === section.id) {
                        return <RecipeSection id={section.id} 
                            onSectionDone={this.setSectionDone} 
                            doneSteps={section.id in this.props.currentSectionsDone ? this.props.currentSectionsDone[section.id] : undefined}
                            onStepDone={this.onStepDone}
                            amount={currentAmount !== 0 ? currentAmount : currentRecipe.minServings}  
                            title={section.title} 
                            key={section.id} 
                            minAmount={currentRecipe.minServings}
                            steps={section.steps} />
                    }
                })}
            </View>
        )
    }

    renderImage() {
        const {currentRecipe} = this.props
        return (
            <View style={IS_TABLET ? styles.imageContainerTablet : styles.imageContainer}>
                <MfImage style={styles.image} source={currentRecipe.smallImage} loader={<ImageLoader />} />
            </View>
        )
    }

    renderActionContainer() {
        const {currentRecipe} = this.props
        return (
            <View style={[styles.actionContainer, IS_TABLET ? styles.actionContainerTablet : null]}>
                <FavoriteAnimation 
                    isFavorite={this.recipeIsFavorite()} 
                    onAdd={() => this.props.addToFavorites(currentRecipe.id)} 
                    onRemove={() => this.props.removeFromFavorites(currentRecipe.id)} />
            </View>
        )
    }

    renderEndContainer() {
        return (
            <View style={styles.endContainer}>
                <MfText style={styles.doneText} textStyle={TEXT_STYLES.HEADER}>{getText('- Smaklig måltid!')}</MfText>
                <Button icon={Logo as ImageSourcePropType} variant={BUTTON_VARIANTS.LARGE} style={styles.endButton} title={getText('Till startsidan')} onPress={() => this.props.onNavigate()} />
            </View>
        )
    }

    renderIngredients() {
        const ingredients = this.getIngredients(false)
        const {currentAmount, currentRecipe} = this.props
        const sortedIngredients : Ingredient[] = []
        Object.keys(ingredients).forEach(ing => {
            sortedIngredients.push(ingredients[ing])
        })
        
        sortedIngredients.sort((a, b) => a.sortOrder - b.sortOrder)
        return (
            <View style={styles.cardIngredients}>
                {sortedIngredients.map(ingredient => {
                    const displayQuantity = QuantityHandler.convertQuarters(ingredient.quantity / currentRecipe.minServings * currentAmount)
                    const displayMeasure = QuantityHandler.shouldUsePlural(ingredient, currentAmount, currentRecipe.minServings) ? 
                        ingredient.measurePlural : ingredient.measure
                    return (
                        <ListItem
                        key={ingredient.name} 
                        primaryContent={ingredient.name} 
                        accent={false}
                        secondaryContent={`${displayQuantity} ${displayMeasure}`} />
                    )
                }
            )}
            </View>
        )
    }

    getNutritions(nuts: Nutrition[], ings: ing, splitAmount: number) {
        return calculateNutritions(ings, nuts, splitAmount)
    }

    onShowNutritions() {
        this.props.onModalClose()
        this.setState({showNutritionModal: true})
    }

    render() {
        const { currentRecipe, currentAmount} = this.props
        const tips = this.getTips()
        const webScroll = {
            height: WEB_SCROLLVIEW_STYLE.height,
            paddingBottom: 80
        }
        return (
            <View style={IS_WEB ? styles.contentContainerWeb : null}>
                {IS_TABLET ? 
                <View style={styles.tabletContainer}>
                    <View style={styles.tabletLeft}>
                        {this.renderActionContainer()}
                        <ScrollView style={IS_WEB ? webScroll : styles.tabletScrollView}>
                            {this.renderInstructions()}
                            {this.state.sectionsDone ? this.renderEndContainer() : null}
                        </ScrollView>    
                    </View>
                    <View style={styles.tabletRight}>
                        <AnimatedScroll 
                            image={{uri: currentRecipe.largeImage}} 
                            tablet={true}
                            customHeight={250}>
                            {this.renderIngredients()}
                        </AnimatedScroll>
                    </View>
                </View>
                :
                <ScrollView style={IS_WEB && webScroll}>
                    <View style={styles.headerContainer}>
                        {this.renderImage()}
                        {this.renderActionContainer()}
                    </View>
                    
                    {this.renderInstructions()}

                    {this.state.sectionsDone ? this.renderEndContainer() : null}
                </ScrollView>
                }
                <InfoModal 
                    onShowNutritions={() => this.onShowNutritions()}
                    visible={this.props.showModal} 
                    onClose={this.props.onModalClose} 
                    tips={tips} />
                <NutritionModal 
                    nutritions={this.getNutritions(this.props.nutritions, this.getIngredients(false), currentAmount)}
                    splitAmount={currentAmount}
                    measurement={currentRecipe.servingsLabelSingular} 
                    visible={this.state.showNutritionModal} 
                    onClose={() => this.setState({showNutritionModal: false})}/>
            </View>
        )
    }
}

const mapStateToProps = (state: IState) => {
    return {
        currentRecipe: state.recipeState.currentRecipe,
        currentAmount: state.recipeState.currentAmount,
        currentAlteredSections: state.recipeState.currentAlteredSections,
        favorites: state.favoriteState.favorites,
        currentSectionsDone: state.recipeState.currentSectionsDone,
        nutritions: state.nutritionState.nutritions
    } as ReduxProps
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        addToFavorites: (id: number) => dispatch(addToFavorites(id)),
        removeFromFavorites: (id: number) => dispatch(removeFromFavorites(id)),
        setSectionsDone: (s: CheckedSections) => dispatch(setCurrentSectionsDone(s))
    }
}

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(mapStateToProps, mapDispatchToProps)(RecipeInstructionsContainer)
