import React from 'react'
import {Component} from 'react'
import { View, Platform } from 'react-native';
import { Screen } from '../../../interface';
import MfText, { TEXT_STYLES } from '../../../components/mf_text';
import SearchContainer from './search_container';
import { NavigationScreenProps } from 'react-navigation';
import FilterButton from '../../../components/nav_buttons/filter_button';
import { Routes } from '../../../navigation/stacks';


class Search extends Screen {

    state = {
        showModal: false,
        navTitle: ''
    }

    static navigationOptions = ({navigation}: NavigationScreenProps) => {
        return {
            headerTitle: navigation.getParam('title'),
            // headerRight: <FilterButton onPress={navigation.getParam('filterClicked')} />
        }
    }

    componentDidMount() {
        super.componentDidMount()
        if (Platform.OS !== 'web') {
            this.props.navigation.setParams({filterClicked: () => this.filterClicked()})
        } else {
            // this.props.webNavigation.setHeaderRight(
            //     <FilterButton onPress={() => this.filterClicked()} />
            // )
        }
    }

    filterClicked() {
        this.setState({showModal: true})
    }

    navigate() {
        if (Platform.OS === 'web') {
            this.props.webNavigate(Routes.RECIPE_OVERVIEW, this.state.navTitle)
        } else {
            this.props.navigation.navigate(Routes.RECIPE_OVERVIEW, {title: this.state.navTitle})
        }        
    }

    onModalClose = () => {
        this.setState({showModal: false})
    }

    setTitle = (title: string) => {
        this.setState({navTitle: title})
    }

    render() {
        return (
            <SearchContainer 
            setTitle={this.setTitle} 
            onNavigate={() => this.navigate()} 
            // showModal={this.state.showModal} 
            // onModalClose={this.onModalClose} 
            />
        )
    }
}

export default Search