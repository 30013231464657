import React from 'react'
import {Component} from 'react'
import {IScreen, Screen} from '../../../interface'
import { Platform } from 'react-native'
import {Routes} from '../../../navigation/stacks'
import RecipeListContainer from './recipe_list_container'
import { NavigationScreenProps } from 'react-navigation';

// If override componentDidMount call super.componentDidMount() to disable icon in web navBar
class RecipeList extends Screen {

    state = {
        navTitle: ''
    }

    static navigationOptions = ({navigation}: NavigationScreenProps) => {
        return {
            headerTitle: navigation.getParam('title')
        }
    }

    navigate() {
        if (Platform.OS === 'web') {
            this.props.webNavigate(Routes.RECIPE_OVERVIEW, this.state.navTitle)
        } else {
            this.props.navigation.navigate(Routes.RECIPE_OVERVIEW, {title: this.state.navTitle})
        }        
    }

    setNavTitle = (title: string) => {
        this.setState({navTitle: title})
    }

    render() {
        return (
            <RecipeListContainer setTitle={this.setNavTitle}  onNavigate={() => this.navigate()} />
        )
    }
}

export default RecipeList
