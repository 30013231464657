import React from 'react'
import {Component} from 'react'
import MfText, { TEXT_STYLES } from '../../../../components/mf_text';
import { View } from 'react-native';
import { IState, ICategoryState, IRecipeState } from '../../../../model/state'
import { connect } from 'react-redux'
import { RecipeCategory, Recipe, RecipeStub } from '../../../../model'
import { ScrollView} from 'react-native'
import {IContainer} from '../../../../interface'
import Card from '../../../../components/card'
import Search from '../../../../components/search';
import styles from './styles'
import MfContainer from '../../../../components/mf_container';
import FilterModal from './filter_ modal';
import { FilterResult } from './filter_ modal/filter_modal'
import {setRecipe, setRecipeAmount} from '../../../../redux/actions/recipes'
import { backgrounds } from '../../../../components/mf_container/mf_container';
import Button from '../../../../components/button/button';
import { getText } from '../../../../helpers/text/translation';
import { IS_TABLET } from '../../../../helpers/device';


interface ReduxProps {
    dataLoaded: boolean,
    categoryState: ICategoryState
    recipeState: IRecipeState
}

interface DispatchProps {
    setCurrentRecipe: Function,
    setAmount: (a: number) => void
}

interface ReactProps extends IContainer {
    // showModal: boolean,
    // onModalClose: () => void
}

type Props = ReduxProps & ReactProps & DispatchProps

interface State {
    recipes: RecipeStub[], 
    allRecipes: RecipeStub[],
    filter: boolean,
    filteredRecipes: RecipeStub[]
}

class SearchContainer extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        
        this.state.recipes = this.props.recipeState.recipes.map(r => 
            ({
                id: r.id,
                name: r.name,
                image: r.smallImage
            })     
        )
        this.state.allRecipes = this.state.recipes.slice(0)
    }

    state: State = {
        recipes: [],
        allRecipes: [],
        filter: false,
        filteredRecipes: []
    }

    handleSearchResult = (searchResult: number[], searchTerm: string) => {
        if (this.state.filter) {
            const recipes = searchTerm == '' ? this.state.filteredRecipes.slice(0) :
                this.state.filteredRecipes.filter( r => searchResult.indexOf(r.id) != -1)
            this.setState({recipes})

        } else {
            const recipes = searchTerm == '' ? this.state.allRecipes.slice(0) :
                this.state.allRecipes.filter( r => searchResult.indexOf(r.id) != -1)
            this.setState({recipes})
        }
    }

    handleFilterResult = (filterResult: FilterResult[]) => {
        const recipes = this.state.allRecipes.filter(r => filterResult.map(f => f.recipeId).indexOf(r.id) != -1)
        this.setState({recipes: recipes, filter: true, filteredRecipes: recipes})
    }

    cardPressed = async (id: number, name: string) => {
        await this.props.setCurrentRecipe(id)
        this.props.setTitle(name)
        this.props.onNavigate()        

    }

    clearFilter() {
        const recipes = this.state.allRecipes.slice(0)
        this.setState({recipes: recipes, filter: false})
    }

    render() {
        const {recipeState, categoryState} = this.props
        const {filter, recipes} = this.state
        return (
            <MfContainer style={styles.container} backgroundImage={backgrounds.RECIPE}>
                <Search 
                recipes={recipeState.recipes} 
                categories={categoryState.categories}
                callback={this.handleSearchResult}
                onCancel={() => {}}
                resultType='RECIPES'
                placeHolder={getText('Ingrediens, recept, valfritt sökord')}
                />
                {recipes && recipes.length > 0 ? 
                    <ScrollView keyboardShouldPersistTaps='always' contentContainerStyle={styles.list}>

                        {recipes.map(recipe => 
                            <Card 
                            small={!IS_TABLET} 
                            onPress={() => this.cardPressed(recipe.id, recipe.name)} 
                            key={recipe.id} 
                            title={recipe.name} 
                            image={{uri: recipe.image}} />
                        )}
                    </ScrollView>
                    :
                    <View style={styles.emptyResult}>
                        <MfText textStyle={TEXT_STYLES.SUB_HEADER}>{getText('Inga recept hittades')}</MfText>
                    </View>
                }
                {filter ? 
                <View style={styles.filterButtonC}>
                    <Button onPress={() => this.clearFilter()} title={getText('Rensa filter')} /> 
                </View>
                : null}
                
                {/* <FilterModal 
                    filterCallback={this.handleFilterResult}
                    recipeState={recipeState}
                    categoryState={categoryState}
                    onClose={() => this.props.onModalClose()} 
                    visible={this.props.showModal}
                    activeFilter={filter}
                    /> */}
            </MfContainer>
        )
    }
}

const mapStateToProps = (state: IState) => {
    return { 
        categoryState: state.categoryState,
        recipeState: state.recipeState,
        dataLoaded: state.categoryState.dataLoaded && state.recipeState.dataLoaded
    } as ReduxProps
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setCurrentRecipe: (id: number) => dispatch(setRecipe(id)),
        setAmount: (amount: number) => dispatch(setRecipeAmount(amount))
    }
}

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(mapStateToProps, mapDispatchToProps)(SearchContainer)