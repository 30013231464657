import {StyleSheet, Platform} from 'react-native'
import { WINDOW_SIZE } from '../../../config/style';
import { IS_WEB } from '../../../config/style/web_helper';

const isLowAndroidVersion = Platform.OS === 'android' && Platform.Version <= 25

export default StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: IS_WEB ? 60 : undefined
    },
    linearGradient: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    buttonContainer: {
        marginTop: 20,
        justifyContent: 'space-around',
        flex: 3
    },

    logoContainer: {
        flex: 1,
        justifyContent: 'flex-end'
    },
    logo: {
        height: 65,
        resizeMode: 'contain',
        marginBottom: 30
    },
})