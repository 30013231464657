import {StyleSheet} from 'react-native'
import { PRIMARY_THEME_COLORS } from '../../../../config/style';
import { backgrounds } from '../../../../components/mf_container/mf_container';

export default StyleSheet.create({
    headerContainer: {
        width: '100%',
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: PRIMARY_THEME_COLORS.inventory,
        color: 'white'
    },
    headerRight: {
        width: 35,
        height: 35
    },
    image: {
        width: 35,
        height: 35
    },
    container: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.1)',
    },
    contentContainer: {
        height: '100%',
        backgroundColor: 'white',
        alignItems: 'center'
    },
    scrollContainer: {
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        flexDirection: 'row',
        paddingBottom: 20,
        width: '100%'
    },
    emptyResult: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 200
    }
})