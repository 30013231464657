import React from 'react'
import {Component} from 'react'
import {View, SafeAreaView, Image, ImageSourcePropType, Keyboard} from 'react-native'
import styles from './styles'
import MfModal, { animationTypes } from '../../../../../components/mf_modal'
import MfText, { TEXT_STYLES } from '../../../../../components/mf_text';
import { getText } from '../../../../../helpers/text/translation';
import Button, { BUTTON_VARIANTS } from '../../../../../components/button/button';
import close from '../../../../../../assets/ui/close_black.png'
import Touchable from '../../../../../components/touchable';
import { IngredientBase, ShoppingIngredient, IngredientMeasures } from '../../../../../model';
import CheckableItem from '../../../../../components/checkable_item';
import MfSearchBar from '../../../../../components/search/mf_search_bar';
import KeyboardHideView from '../../../../../components/keyboard_hide_view'
import { FlatList } from 'react-native-gesture-handler'


interface IAddIngredientModal {
    visible: boolean,
    onClose: Function,
    onAdd: Function,
    ingredients: IngredientBase[],
    lastUserIngredientId: number,
    onAddUserIngredient: (i: IngredientBase) => Promise<void>,
    nextShoppingItemId: number,
    standardMeasures: IngredientMeasures
}
export interface IaddedItem {
    [key: number]: ShoppingIngredient
}

class AddIngredientModal extends Component<IAddIngredientModal> {
    state = {
        textInput: getText('Välj i listan eller skriv valfritt'),
        ingredients: this.getIngredients(),
        addedItems: this.getAddedItems(),
        tempId: 999999,
        nextId: this.props.nextShoppingItemId,
        textValue: '',
    }

    getAddedItems() {
        const items : IaddedItem = {}
        return items
    }

    componentWillReceiveProps(nextProps: IAddIngredientModal) {
        if (nextProps.visible !== this.props.visible) {
            console.log('comp will rec ai')
            this.setState({ingredients: this.getIngredients()})
        }
    }

    getIngredients() {
        const ingredients = Array.from(this.props.ingredients)
        ingredients.sort((a, b) => a.name.localeCompare(b.name))
        return  ingredients
    }



    async createShoppingIngredient(ingredient: IngredientBase) {
        const inIngredients = this.props.ingredients.find(i => i.id === ingredient.id)
        const {standardMeasures} = this.props
        if (!inIngredients) {
            await this.props.onAddUserIngredient({
                id: this.props.lastUserIngredientId,
                name: ingredient.name,
                pluralName: '',
                category: ingredient.category,
                categoryName: ingredient.categoryName
            })
        }
        const quantity = inIngredients && ingredient.id in standardMeasures ? standardMeasures[ingredient.id].quantity : 0
        const measure = inIngredients && ingredient.id in standardMeasures ? standardMeasures[ingredient.id].measure : ''
        const newIngredient : ShoppingIngredient = {
            id: ingredient.id === this.state.tempId ? this.props.lastUserIngredientId : ingredient.id,
            shoppingItemId: this.state.nextId,
            categoryId: ingredient.category,
            category: ingredient.categoryName,
            measure: '',
            measureFull: '',
            measureFullPlural: '',
            measureFullPluralShop: '',
            measureFullShop: measure,
            measurePlural: '',
            measurePluralShop: '',
            name: ingredient.name,
            quantity: 0,
            sectionId: 0,
            useId: 0,
            measureShop: measure,
            quantityShop: quantity,
            roundUpShop: false,
            pluralName: '',
            externalGroup: '',
            externalId: 0,
            externalName: '',
            weight: 0,
            sortOrder: 0
        }
        this.setState({nextId: this.state.nextId +1})
        return newIngredient
    }

    sortOnAdded(newIngredient?: IngredientBase): IngredientBase[] {
        const arr = Array.from(this.props.ingredients)
        if (newIngredient && !arr.includes(newIngredient)) arr.push(newIngredient)
        return arr.sort((a, b) => {
            if (newIngredient && a.id == newIngredient.id) return -1
            if (newIngredient && b.id == newIngredient.id) return  1
            if (a.id in this.state.addedItems) {
                if (b.id in this.state.addedItems) {
                    return a.name.localeCompare(b.name)
                }
                return -1
            } 
            if (b.id in this.state.addedItems) return 1
            return a.name.localeCompare(b.name)
        })
    }

    onCancelSearch() {
        console.log('ON search cancel')
        if (Object.keys(this.state.addedItems).length > 0) {
            console.log('on search cancel IF')
            this.setState({ingredients: this.sortOnAdded()})
        }
    }

    async onPressItem(ingredient: IngredientBase) {
        Keyboard.dismiss()
        const obj : IaddedItem = {...this.state.addedItems}
        if (obj.hasOwnProperty(ingredient.id)) {
            delete obj[ingredient.id]
        } else {
            obj[ingredient.id] = await this.createShoppingIngredient(ingredient)
            console.log(obj)
        }
        //Sort ingredients with addedItems first
        if (this.state.textValue.length > 0) {
            this.setState({ingredients: this.sortOnAdded(ingredient)}, () => console.log('after sort', this.state.ingredients))
        }
        this.setState({addedItems: obj, textValue: ''})
    }
    
    onClose() {
        this.props.onClose()
    }
    //WARNING: Fix id!
    onSearchChange(search: string) {
        const arr = Array.from(this.state.ingredients.filter(i => i.name.toLowerCase().includes(search.toLowerCase())))
        const newItem = [{
            name: search,
            id: this.props.lastUserIngredientId,
            category: '',
            categoryName: 'Egna varor'
        }]
        this.setState({
            ingredients: search ? arr.length > 0 ? arr : newItem : this.getIngredients(),
            tempId: this.state.tempId + 1,
            textValue: search
        })
    }

    onAddDone() {
        const {addedItems} = this.state
        const arr : ShoppingIngredient[] = []
        Object.keys(addedItems).forEach(item => {
            if ('newItem' in addedItems[parseInt(item)]) {
                
            }
            arr.push(addedItems[parseInt(item)])
        })
        this.props.onAdd(arr)
        this.setState({addedItems: {}, ingredients: this.getIngredients()})
        this.props.onClose()
    }
    render() {
        const {ingredients} = this.state
        return(
            <MfModal onClose={() => this.props.onClose()} visibile={this.props.visible} animationType={animationTypes.SLIDE} transparent={true}>
                <SafeAreaView style={styles.container}>  
                    <View style={styles.contentContainer}>
                        <View style={styles.headerContainer}>
                            <Touchable onPress={() => this.onClose()}>
                                <View>
                                    <Image style={styles.image} source={close as ImageSourcePropType} />
                                    
                                </View>
                            </Touchable>
                            <MfText textStyle={TEXT_STYLES.HEADER}>{getText('Lägg till vara')}</MfText>
                            <View style={styles.headerRight}></View>
                        </View>
                        <View style={styles.searchContainer}>
                            <MfSearchBar
                                placeholder={this.state.textInput}  
                                onChangeText={(text: string) => this.onSearchChange(text)}
                                value={this.state.textValue}
                                onCancel={() => this.onCancelSearch()}
                                />
                            <FlatList 
                                keyboardShouldPersistTaps='handled'
                                data={ingredients}
                                renderItem={(item) => {
                                    return (
                                        <CheckableItem 
                                            key={item.item.id} 
                                            preventUncheckedUpdate={true}
                                            style={styles.searchItem} 
                                            onPress={() => this.onPressItem(item.item)} 
                                            checked={this.state.addedItems.hasOwnProperty(item.item.id)} 
                                            customComponent={
                                                <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{item.item.name}</MfText> 
                                            }/>
                                    )
                                }}
                            />
                        </View>
                        <KeyboardHideView style={styles.footer}>
                            <Button style={styles.button} variant={BUTTON_VARIANTS.NO_RADIUS} title={getText('Lägg till varor')} onPress={() => this.onAddDone()} /> 
                        </KeyboardHideView>
                    </View>
                </SafeAreaView>
            </MfModal>
        )
    }
}

export default AddIngredientModal