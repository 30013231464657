import { StyleSheet } from 'react-native'
import { PRIMARY_THEME_COLORS, TEXT_COLORS } from '../../../../../config/style';


export default StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        backgroundColor: 'white'
    },
    header: {
        height: 60,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: PRIMARY_THEME_COLORS.variation
    },
    headerRight: {
        width: 40
    },
    icon: {
        height: 40,
        width: 40
    },
    contentContainer: {
        flex: 1
    },
    footer: {
        height: 100,
        alignItems: 'center'
    },
    button: {
        backgroundColor: PRIMARY_THEME_COLORS.recipes
    },
    listHeader: {
        width: '100%',
        height: 50,
        justifyContent: 'center',
        
        // alignItems: 'center'
    },
    headerText: {
        color: TEXT_COLORS.light
    },
    listItem: {
        width: '100%',
        height: 50,
        backgroundColor: 'white',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 30,
        paddingRight: 30,
        borderBottomColor: 'grey',
        borderBottomWidth: 1
    },
    listContainer: {
        marginBottom: 10,
        padding: 20
    },
    listItemHighlight: {
        // backgroundColor: 'green'
    },
    checkBox: {
        width: 20,
        height: 20
    },
    text: {
        fontSize: 24
    },
    textChecked: {
        fontSize: 24,
        fontFamily: 'clan-pro-narr-bold',
        color: PRIMARY_THEME_COLORS.variation
    }

})