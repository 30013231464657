import React from 'react'
import {Component} from 'react'
import { View, ScrollView } from 'react-native';
import { RecipeStub, RecipeCategory } from '../../../../model';
import { IContainer } from '../../../../interface';
import { IState } from '../../../../model/state';
import { connect } from 'react-redux';
import Card from '../../../../components/card';
import styles from './styles'
import {setRecipe, setRecipeAmount, setCurrentSectionsDone} from '../../../../redux/actions/recipes'
import MfContainer from '../../../../components/mf_container';
import { backgrounds } from '../../../../components/mf_container/mf_container';
import { firstCapital, getText } from '../../../../helpers/text/translation';
import EmptyCard from '../../../../components/empty_card';
import Loader from '../../../../components/loader';
import { CheckedSections } from '../../recipe_instructions/recipe_instructions_container/recipe_instructions_container';


//From mapStateToProps
interface ReduxProps {
    currentCategory: RecipeCategory,
    preLoadedImages: boolean
}

interface DispatchProps {
    setCurrentRecipe: Function,
    setAmount: Function,
}

//Passed from JSX component
export interface ReactProps extends IContainer {}

type Props = ReduxProps & ReactProps & DispatchProps
// & DispatchProps

//Internal state
interface State {}

class RecipeListContainer extends Component<Props, State> {

    componentDidMount() {

    }

    getRecipeName(id: number) {
        const {currentCategory} = this.props
        if (currentCategory.recipes) {
            const r = currentCategory.recipes.find(re => re.id === id)
            if (r) {
                return r.name
            } else {
                return ''
            }
        } else {
            return ''
        }
    }
    async cardPressed(id: number) {
        await this.props.setCurrentRecipe(id)
        this.props.setTitle(this.getRecipeName(id))
        this.props.onNavigate()        
    }
    addEmptyCards(recipes: RecipeStub[] | undefined) {
        const emptyCards = []
        if (recipes !== undefined) {
            let amount = recipes.length
            while (amount % 3 !== 0) {
                amount++
            }
            const result = amount - recipes.length
            
            for(let i = 0;i<result;i++) {
                emptyCards.push(<EmptyCard key={i+'x'} />)
            }
        }
        return emptyCards
    }

    render() {
        const {preLoadedImages} = this.props
        return (
            <MfContainer backgroundImage={backgrounds.RECIPE}>
                {!preLoadedImages ?
                <Loader text={getText('Detta kan ta en liten stund')} />
                :
                <ScrollView contentContainerStyle={styles.container}>
                    {this.props.currentCategory.recipes ? 
                        this.props.currentCategory.recipes.map(recipe => {
                            return <Card title={firstCapital(recipe.name)} key={recipe.id} image={{uri: recipe.image}} onPress={() => this.cardPressed(recipe.id)} />
                        })
                        :
                        null
                    }
                    {this.props.currentCategory.recipes ? 
                        this.addEmptyCards(this.props.currentCategory.recipes).map(el => el)
                        :
                        null
                    }
                    {/* <EmptyCard /> */}
                    
                </ScrollView>
                }
            </MfContainer>
        )
    }
}

const mapStateToProps = (state: IState) => {
    return {
        currentCategory: state.categoryState.currentCategory,
        preLoadedImages: state.recipeState.preLoadedImages
    } as ReduxProps
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setCurrentRecipe: (id: number) => dispatch(setRecipe(id)),
        setAmount: (amount: number) => dispatch(setRecipeAmount(amount)),
    }
}

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(mapStateToProps, mapDispatchToProps)(RecipeListContainer)