import React from 'react'
import {Component} from 'react'
import {Platform, View} from 'react-native'
import {NavigationScreenProp} from 'react-navigation'
import { Routes } from '../navigation/stacks';

export interface IScreen {
    navigation: NavigationScreenProp<any, any>
    webNavigate: WebNavigate,
    webNavigation: {
        setHeaderRight: Function,
        actionCallback: Function,
        title: string,
        setNavBarBackgroundColor: Function,
        goBack: () => void
    }
}

interface WebNavigate {
    (route: Routes, nextTitle: string): void
}


export class Screen extends Component<IScreen> {

    componentDidMount() {
        if (Platform.OS === 'web') {
            this.props.webNavigation.setHeaderRight(<View></View>)
        } 
    }
}
