import React, {Component} from 'react'
import {Stacks} from '../app/navigation/stacks'
import WebRouter from './navigation/router';
import WebLoader from './web_loader'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../app/config/store';
import query from 'query-string'
import './fonts.css'
import android from './test_assets/install_android.png'
import ios from './test_assets/install_apple.png'
import testFlight from './test_assets/install_testflight.png'
import './test_assets/style.css'

const LoadingView = () => <div>Laddar..!</div>

export default class WebContainer extends Component {
    state = {
        isAllowed: false,
        isTestLink: false
    }
    componentDidMount() {
        this.setState({isAllowed: this.checkQuery()})
        this.checkTestLink()
    }
    checkQuery() {
        const q = query.parse(location.search)
        return process.env.NODE_ENV === 'development' || 'dev' in q
    }

    checkTestLink() {
        this.setState({isTestLink: location.pathname === '/test/index.html'})
    }

    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={<LoadingView />} persistor={persistor}>
                        {/* <WebRouter stacks={Stacks} /> */}
                        {this.state.isTestLink ?
                        <div className='test_container'>
                            <a href="https://itunes.apple.com/us/app/testflight/id899247664?mt=8">
                                <img className='test_img' src={testFlight} alt='Installera TestFlight' />
                            </a>
                            <a href='https://testflight.apple.com/join/cs3yeOfb'>
                                <img className='test_img' src={ios} alt='Installera MatFröjd (iPhone & iPad)' />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.wopii.matfrojd'>
                                <img className='test_img' src={android} alt='Installera MatFröjd (Android)' />
                            </a>    
                        </div>
                        :
                        this.state.isAllowed ?
                            <WebLoader />
                            :
                            <h1>Kommer snart</h1>    
                    }
                </PersistGate>
            </Provider>
        )
    }
}