import React from 'react'
import {Component} from 'react'
import { View, Image, ImageSourcePropType} from 'react-native';
import styles from './styles'
import Hamburger from '../../../../assets/ui/material-menu.png'
import {INavButton} from '../nav_button/i_nav_button'
import Touchable from '../../touchable';
import icon from '../../../../assets/ui/information-outline.png'
import NavButton from '../nav_button'

class InformationButton extends Component<INavButton> {
    render() {
        /*
        return (
            <Touchable style={styles.container} onPress={this.props.onPress}>
                <Image style={styles.icon} source={icon as ImageSourcePropType} />
            </Touchable>
            
        )
        */
        return <NavButton onPress={() => (this.props.onPress as Function)()} icon={icon as ImageSourcePropType} styles={styles}/>
    }
}

export default InformationButton