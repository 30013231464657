import {StyleSheet} from 'react-native'
import { SEARCH_VIEW_SIZES } from '../../../config/style';

export default StyleSheet.create({
    backgroundColor: {
        backgroundColor: 'white'
    },
    input: {
        height: SEARCH_VIEW_SIZES.inputHeight,
    },
    searchBarWeb: {
        paddingLeft: 10,
        height: 45,
        borderLeftWidth: 1,
        borderColor: 'grey',
        flex: 1,
    },
    icon: {
        height: 30,
        width: 30,
        marginRight: 10,
        marginLeft: 10
    },
    containerNative: {
        width: '100%',
        // backgroundColor: 'white',
    },
    containerWeb: {
        flexDirection: 'row',
        height: 50,
        backgroundColor: 'white',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: 'grey',
        width: window.innerWidth
    },
    cancelText: {
        color: 'black'
    }
})