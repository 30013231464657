import React from 'react'
import {Component} from 'react'
import styles from './styles'
import { Animated } from 'react-native';

interface IAnimatedOpacity {
    duration: number,
    delay: number,
    reverse?: boolean,
    onAnimationEnd: Function,
    loop?: boolean,
    from?: number,
    to?: number
}

class AnimatedOpacity extends Component<IAnimatedOpacity> {
    state = {
        opacity: new Animated.Value(this.props.from || 0),
        toValue: 1
    }
    componentDidMount() {
        this.ifReversed()
        this.props.loop ? this.animateLoop() : this.animate()
    }
    ifReversed() {
        if (this.props.reverse) {
            this.state.opacity.setValue(1)
            this.setState({toValue: 0})
        }
    }
    animate() {
        const {duration, delay} = this.props
        setTimeout(() => {
            Animated.timing(this.state.opacity, {
                toValue: this.state.toValue,
                duration: duration
            }).start(() => this.props.onAnimationEnd())    
        }, delay)
    }

    animateLoop() {
        const {duration, delay, from, to} = this.props
        Animated.loop(
            Animated.sequence([
                Animated.timing(this.state.opacity, {
                    toValue: to || 1,
                    duration: duration
                }),
                Animated.timing(this.state.opacity, {
                    toValue: from || 0,
                    duration: duration
                })
            ])
        ).start()
    }

    render() {
        return(
            <Animated.View style={{opacity: this.state.opacity, zIndex: 20}}>
                {this.props.children}
            </Animated.View>
        )
    }
}

export default AnimatedOpacity
