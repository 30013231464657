import React from 'react'
import {Component} from 'react'
import {IScreen, Screen} from '../../../interface'
import {View, Platform} from 'react-native'
import RecipeInstructionsContainer from './recipe_instructions_container'
import { Routes } from '../../../navigation/stacks'
import InformationButton from '../../../components/nav_buttons/information_button'
import { NavigationScreenProps } from 'react-navigation'



class RecipeInstructions extends Screen {
    state = {
        showModal: false
    }
    static navigationOptions = ({navigation}: NavigationScreenProps) => {
        return {
            headerRight: <InformationButton onPress={navigation.getParam('buttonOnPressTwo')} />,
            headerTitle: navigation.getParam('title')
        }
    }

    componentDidMount() {
        super.componentDidMount()
        if (Platform.OS !== 'web') {
            this.props.navigation.setParams({buttonOnPressTwo: () => this.toogleModal()})
        } else {
            this.props.webNavigation.setHeaderRight(
                <InformationButton onPress={() => this.toogleModal()} />
            )
        }
    }

    toogleModal = () => {
        
        this.setState({showModal: true})
    }

    onModalClose = () => {
        this.setState({showModal: false})
    }

    navigate() {
        if (Platform.OS !== 'web') {
            this.props.navigation.popToTop()
        } else {
            this.props.webNavigate(Routes.START, 'Matfröjd')
        }
    }

    render() {
        return (
            <RecipeInstructionsContainer showModal={this.state.showModal} onModalClose={this.onModalClose} setTitle={() => {}} onNavigate={() => this.navigate()} />
        )
    }
}

export default RecipeInstructions
