import {StyleSheet} from 'react-native'
import { NAVBAR_SIZE, PRIMARY_THEME_COLORS } from '../../config/style'

export default StyleSheet.create({
    item: {
        height: 55,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'grey'
    },
    optionsContainer: {
        
    },
    icon: {
        height: 25,
        width: 25
    },
    text: {
        marginLeft: 10
    },
    menuButtonWeb: {
        position: 'relative'
    },
    menuWeb: {
        position: 'absolute',
        right: 0,
        bottom: -(NAVBAR_SIZE.height+47)
    },
    menuItemWeb: {
        backgroundColor: 'white',
        minWidth: 120,
        borderBottomColor: PRIMARY_THEME_COLORS.grey,
        borderBottomWidth: 1,
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    }
})