import { IPantryAction } from "../actions"
import { Types } from '../actions/types'
import { IPantryState } from '../../model/state'
// import AsyncStorage from '@react-native-community/async-storage'
// import { persistReducer } from "redux-persist";

const initialState: IPantryState = {
    ingredients: []
}
// const config = {
//     key: 'pantryState',
//     storage: AsyncStorage,
//     blacklist: ['ingredients']
// }

const reducer =  (state = initialState, action: IPantryAction): IPantryState => {
    switch (action.type) {
        case Types.ADD_TO_PANTRY:
            return { 
                ...state, 
                ingredients: [...state.ingredients, ...action.ingredients]
            }
        case Types.REMOVE_FROM_PANTRY:
            return { 
                ...state, 
                ingredients: state.ingredients.filter(ing => ing.id !== action.id)
            }
        case Types.CLEAR_PANTRY:
            return { 
                ...state, 
                ingredients: []
            }
        default: return state
    }
}

// export default persistReducer(config, reducer)
export default reducer