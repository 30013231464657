import { StyleSheet } from 'react-native'
import { WINDOW_SIZE, NAVBAR_SIZE, PRIMARY_THEME_COLORS, SECONDARY_THEME_COLORS } from '../../../../config/style';
import { IS_TABLET } from '../../../../helpers/device';
import { WEB_SCROLLVIEW_STYLE } from '../../../../config/style/web_helper';

const buttonHeight = 80

export default StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        overflow: 'hidden'
    },

    // varför påverkar inte detta webversion?
    contentContainer: {
        // flexWrap: 'wrap',
        // flexDirection: 'row'
    },
    // MarginTop: 60 behövs pga av navbar
    containerWeb: {
        height: WEB_SCROLLVIEW_STYLE.height,
        width: '100%',
        // overflow: 'hidden',
        // paddingBottom: 60,
        marginTop: 60
    },
    containerWebMobile: {
        
    },
    tabletContainer: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        flexDirection: 'row'
    },
    tabletLeft: {
        flex: 2
    },
    tabletRight: {
        flex: 1,
        padding: 20,
        paddingBottom: 0,
        backgroundColor: SECONDARY_THEME_COLORS.lightGreen
    },
    tabletScrollRight: {
        
    },
    buttonLeft: {
        backgroundColor: PRIMARY_THEME_COLORS.variation,
        height: buttonHeight,
        minWidth: '50%',
        maxWidth: IS_TABLET ? 250 : WINDOW_SIZE.width/2,
        margin: 0
    },
    buttonLeftTablet: {
        borderTopLeftRadius: 50,
        borderBottomLeftRadius: 50,
    },
    buttonRight: {
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
        height: buttonHeight,
        minWidth: '50%',
        maxWidth: IS_TABLET ? 250 : WINDOW_SIZE.width/2,
        margin: 0
    },
    singleButtonRight: {
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
        height: buttonHeight,
        width: WINDOW_SIZE.width
    },
    buttonRightTablet: {
        borderTopRightRadius: 50,
        borderBottomRightRadius: 50,
    },
    singleButtonRightTablet: {
        borderRadius: 50
    },
    
    buttonContainer: {
        height: buttonHeight,
        flexDirection: 'row',
    },
    buttonContainerTablet: {
        marginBottom: 20,
        marginLeft: 20,
        marginRight: 20,
        justifyContent: 'center'
    },
    buttonContainerWeb: {
        height: buttonHeight,
        width: '100%',
        position: 'absolute',
        bottom: 0,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonWidthWeb: {
        width: IS_TABLET ? 200 : '50vw'
    },
    nutrButton: {
        height: 30,
        width: 30,
        borderRadius: 30,
        backgroundColor: PRIMARY_THEME_COLORS.recipes
    },
    portionContainer: {
        justifyContent: 'flex-end',
        flexDirection: 'row',
        alignItems: 'center',
        margin: 10,
        
    },
    portionButtonContainer: {
        flexDirection: 'row',
        
    },
    portionButton: {
        height: 40,
        width: 60
    },
    portionText: {
        fontWeight: 'bold',

    },
    descriptionContainer: {
        padding: 15
    },
    description: {

    },
    showMore: {
        marginTop: 5,
        textAlign: 'right',
        color: PRIMARY_THEME_COLORS.recipes
    }
})