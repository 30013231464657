import React from 'react'
import {Component} from 'react'
import {View, ImageBackground, ImageSourcePropType, Image, Platform} from 'react-native'
import Button from '../../../components/button'
import {IContainer} from '../../../interface'
import {getText} from '../../../helpers/text/translation'
import styles from './styles'
import {Routes} from '../../../navigation/stacks'
import { PRIMARY_THEME_COLORS } from '../../../config/style';
import wall from '../../../../assets/ui/wall_start.jpg'
import wall_tablet from '../../../../assets/wallpappers/wallstart_tablet.jpg'
import { BUTTON_VARIANTS } from '../../../components/button/button';
import {LinearGradient} from 'expo-linear-gradient'
import recepiIcon from '../../../../assets/mf_ui/recept-ikon.png'
import shoppingIcon from '../../../../assets/mf_ui/handla-ikon.png'
import pantryIcon from '../../../../assets/mf_ui/skafferi-ikon.png'
import favoriteIcon from '../../../../assets/mf_ui/favorit-ikon.png'
import hhsLogo from '../../../../assets/logos/hs-logo-ligg_h.png'
import { IS_TABLET } from '../../../helpers/device';
import { IRecipeState, IState, ICategoryState, IIngredientState, INutritionState, IConfigState } from '../../../model/state';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setPreloadedRecipeImages, setPreloadedCategoryImages, setShowDownloadQuestion, setFirstTime } from '../../../redux/actions';
import DownloadContainer from '../../../components/download_container'
import MenuTip from '../../../components/menu_tip'


//From mapStateToProps
interface ReduxProps {
    categories: ICategoryState,
    dataLoaded: boolean,
    recipes: IRecipeState,
    ingredients: IIngredientState,
    nutritions: INutritionState,
    configState: IConfigState
}

interface DispatchProps {
    setPreloadedRecipeImages: (b: boolean) => void,
    setPreloadedCategoryImages: (b: boolean) => void,
    setShowDownloadQuestion: (b: boolean) => void,
    setFirstTime: (b: boolean) => void
}

export interface ReactProps extends IContainer {}
type Props = ReduxProps & ReactProps & DispatchProps
interface State {}

class StartContainer extends Component<Props> {
    navigate(route: Routes) {
        this.props.onNavigate(route)
    }

    state = {
        showDownload: this.props.configState.showDownloadQuestion,
    }

    constructor(p: Props) {
        super(p)
    }

    renderButton(color: string, route: Routes, title: string, icon: ImageSourcePropType) {
        return (
            <Button 
                style={{backgroundColor: color}} 
                onPress={() => this.navigate(route)}
                variant={BUTTON_VARIANTS.LARGE}
                title={title}
                icon={icon}
            />
        )
    }

    onDenyDownload() {
        this.props.setShowDownloadQuestion(false)
        this.setState({showDownload: false})
    }

    onCloseDownloadModal() {
        this.setState({showDownload: false})
    }

    onDownloadSuccess() {
        this.props.setShowDownloadQuestion(false)
    }

    onMenuTipClose() {
        this.props.setFirstTime(false)
    }

    render() {
        const wallpaper = IS_TABLET ? wall_tablet : wall
        const {showDownload} = this.state
        return (
            <ImageBackground style={styles.container} source={wallpaper as ImageSourcePropType}>
                {Platform.OS !== 'web' &&
                    <DownloadContainer 
                    visible={showDownload}
                    onDownloadSuccess={() => this.onDownloadSuccess()}
                    onDeny={() => this.onDenyDownload()}
                    onClose={() => this.onCloseDownloadModal()} />
                }
                {Platform.OS !== 'web' && <MenuTip visible={!showDownload && this.props.configState.firstTime} onClose={() => this.onMenuTipClose()} />}
                <LinearGradient 
                    style={styles.linearGradient} 
                    start={[0.5, 0.5]}
                    end={[.5,0.9]}
                    colors={['rgba(255,255,255,0.1)','white']}>
                    <View style={styles.buttonContainer}>
                        {this.renderButton(PRIMARY_THEME_COLORS.recipes, Routes.CATEGORIES, getText('Recept'), recepiIcon as ImageSourcePropType)}
                        {this.renderButton(PRIMARY_THEME_COLORS.shoppingList, Routes.SHOPPING_LIST, getText('Inköpslista'), shoppingIcon as ImageSourcePropType)}
                        {this.renderButton(PRIMARY_THEME_COLORS.inventory, Routes.PANTRY, getText('Skafferi'), pantryIcon as ImageSourcePropType)}
                        {this.renderButton(PRIMARY_THEME_COLORS.favorites, Routes.FAVORITES, getText('Favoriter'), favoriteIcon as ImageSourcePropType)}
                    </View>
                    <View style={styles.logoContainer}>
                        <Image style={styles.logo} source={hhsLogo as ImageSourcePropType} />
                    </View>
                </LinearGradient>
                
            </ImageBackground>
        )
    }
}

const mapStateToProps = (state: IState) => {
    return { 
        categories: state.categoryState, 
        recipes: state.recipeState,
        dataLoaded: state.categoryState.dataLoaded,
        ingredients: state.ingredientState,
        nutritions: state.nutritionState,
        configState: state.configState
    } as ReduxProps
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setPreloadedRecipeImages: (b: boolean) => dispatch(setPreloadedRecipeImages(b)),
        setPreloadedCategoryImages: (b: boolean) => dispatch(setPreloadedCategoryImages(b)),
        setShowDownloadQuestion: (b: boolean) => dispatch(setShowDownloadQuestion(b)),
        setFirstTime: (b: boolean) => dispatch(setFirstTime(b))
    }
}

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(mapStateToProps, mapDispatchToProps)(StartContainer)