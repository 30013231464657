import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {View, TouchableOpacity, Animated, Image, Easing, Text, ImageSourcePropType, ImageBackground} from 'react-native'
import styles, {linkStyle} from './styles'
import { Icon } from 'react-native-elements';
import { Routes } from '../../../app/navigation/stacks';
import wall from '../../../assets/wallpappers/iPhone-greenwoodback_bild.jpg'
import arrow from '../../../assets/mf_ui/pil-right-ikon@3x.png'
import instructIcon from '../../../assets/mf_ui/handbok-drawer-mf-ikon@3x.png'
import moviesIcon from '../../../assets/mf_ui/demofilm-drawer-mf-ikon@3x.png'
import infoIcon from '../../../assets/mf_ui/om-drawer-mf-ikon@3x.png'
import settingIcon from '../../../assets/mf_ui/instal-drawer-mf-ikon@3x.png'
import MfText, { TEXT_STYLES } from '../../../app/components/mf_text';
import Touchable from '../../../app/components/touchable';

export type rLink = {
    title: string,
    route: string,
    stackName: string
}

interface IWebDrawer {
    onClose: Function,
    onNavigate: Function,
    open: boolean,
    links: rLink[]
}
class WebDrawer extends Component<IWebDrawer> {

    state = {
        slideValue: new Animated.Value(0)
    }
    constructor(props: any) {
        super(props)
        this.slide = this.slide.bind(this)
        this.onClose = this.onClose.bind(this)
    }

    slide() {
        Animated.timing(
            this.state.slideValue,
            {
                toValue: 1,
                duration: 300,
                easing: Easing.ease
                
            }
        ).start()
    }

    onClose(param: string) {
        Animated.timing(
            this.state.slideValue,
            {
                toValue: 0,
                duration: 300
            }
        ).start( () => this.props.onNavigate(param))
        
    }

    onCloseNoAction() {
        Animated.timing(
            this.state.slideValue,
            {
                toValue: 0,
                duration: 300
            }
        ).start( () => this.props.onClose())
    }

    getImg(stackName: string) {
        switch(stackName) {
            case 'RecipeStack':
                return <Icon name='home' color='white' size={40} />
            case 'InstructionStack':
                return <Image source={instructIcon as ImageSourcePropType} style={styles.icon} />
            case 'MovieStack':
                return <Image source={moviesIcon as ImageSourcePropType} style={styles.icon} />    
            case 'InfoStack':
                return <Image source={infoIcon as ImageSourcePropType} style={styles.icon} />
            case 'SettingStack':
                return <Image source={settingIcon as ImageSourcePropType} style={styles.icon} />
            default:
                return <Icon name='home' color='white' size={40} /> 
        }
    }


    render() {
        if (this.props.open) {
            this.slide()
        }
        let {slideValue} = this.state
        const slideTrans = this.state.slideValue.interpolate({
            inputRange: [0,1],
            outputRange: [-350, 0]
        })
        const opacityTrans = this.state.slideValue.interpolate({
            inputRange: [0,1],
            outputRange: ['rgba(0,0,0,0)', 'rgba(0,0,0,0.5)']
        })
        const contentFade = this.state.slideValue.interpolate({
            inputRange: [0,1],
            outputRange: [0,1]
        })
            return(
                <Animated.View style={[styles.container, 
                    this.props.open ? styles.isOpen : styles.isClosed,
                    {backgroundColor: opacityTrans}]}>
                    <View style={styles.drawerContainer}>
                        
                            <Animated.View style={[styles.drawerContent, {transform: [{translateX: slideTrans}]}, {opacity: contentFade}]}>
                            <ImageBackground style={styles.imageBackground} source={wall as ImageSourcePropType}>
                                {/* <View style={styles.header}>
                                    <View style={styles.headerTextContainer}></View>
                                    <View style={styles.headerLogoContainer}></View>
                                </View> */}
                                <View style={styles.links}>
                                    {this.props.links.map(link => {
                                        return (
                                            // <Link style={linkStyle} key={link.title} to={link.route} onClick={this.onClose}>{link.title}</Link>
                                            // TODO: ändra style
                                            <Touchable key={link.title} onPress={() => this.onClose(link.route)}>
                                            <View style={styles.itemStyle}>
                                                {this.getImg(link.stackName)}
                                                <MfText style={styles.itemText} textStyle={TEXT_STYLES.PLAIN_TEXT}>{link.title}</MfText>
                                                <Image source={arrow as ImageSourcePropType} style={styles.arrow} />
                                            </View>
                                        </Touchable>
                                        )
                                    })
                                    }
                                </View>
                                <View style={styles.footer}>
                                </View>
                                </ImageBackground>
                            </Animated.View>
                        
                    </View>
                    <TouchableOpacity style={styles.transContainer} onPress={() => this.onCloseNoAction()}>
                    </TouchableOpacity>        
                </Animated.View>
            )
    }
}

export default WebDrawer
