import { Ingredient, StepIngredient, Recipe, IngredientMeasures } from "../model";
    
enum Measurements {
    L,
    DL,
    ML,
    MSK,
    TSK,
    KRM,
    KG,
    GRAM
}

export default     
    {
        quantityStringForPortions: function(ingredient: StepIngredient, numPortions: number, minPortions: number){
            let quantity = ingredient.useAll ? ingredient.quantityAll : ingredient.quantityUse
            return this.quantityString(quantity*numPortions/minPortions);
        },
        quantityString: function(quantity: number){
            let integralPart = quantity
            let decimalPart = 4.0*(quantity - integralPart);
            let quarters = decimalPart
            let fractionString = "";
            switch (quarters) {
                    case 1:
                    fractionString = "¼";
                    break;
                case 2:
                    fractionString = "½";
                    break;
                case 3:
                    fractionString = "¾";
                    break;
            }
            // return integralPart === 0 ? fractionString : integralPart.toString() + fractionString; 
            return this.convertQuarters(quantity).toString() + fractionString; 
        },

        isStepIngredient(ingredient: StepIngredient | Ingredient): ingredient is StepIngredient {
            return 'useAll' in ingredient
        },

        shouldUsePlural: function(ingredient: StepIngredient | Ingredient, numPortions: number, minPortions: number){
            const quantity = this.isStepIngredient(ingredient) ? (ingredient.useAll ? ingredient.quantityAll : ingredient.quantityUse)
                : ingredient.quantity
            return quantity*numPortions/minPortions >= 2;
        },

        getIndex: function(measurement: string) {
            switch (measurement) {
                case 'l':    return Measurements.L
                case 'dl':   return Measurements.DL
                case 'ml':   return Measurements.ML
                case 'msk':  return Measurements.MSK
                case 'tsk':  return Measurements.TSK
                case 'krm':  return Measurements.KRM
                case 'kg':   return Measurements.KG
                case 'gram': return Measurements.GRAM
            }
        },
        getConvertMap: function() {
            return [
            /*         L           DL      ML       MSK         TSK     KRM    KG      GRAM
            /* L    */ [1,		   10,     1000,    30/0.45,    200,    1000,  -1,     -1   ],
            /* DL   */ [0.1, 	   1, 	   100,     3/0.45,     20,     100,   -1,     -1   ],
            /* ML   */ [0.001,     0.01,   1,       1/15,       1/5,    1,     -1,     -1   ],
            /* MSK  */ [0.45/30,   0.45/3, 15,      1,          3,      15,    -1,     -1   ],
            /* TSK  */ [1/200,     1/20,   5,       1/3,        1,      5,     -1,     -1   ],
            /* KRM  */ [1/1000,    1/100,  1,       1/15,       1/5,    1,     -1,     -1   ],
            /* KG   */ [-1,        -1,     -1,      -1,         -1,     -1,    1,      1000 ],
            /* GRAM */ [-1,        -1,     -1,      -1,         -1,     -1,    1/1000, 1    ]
            ]
        },

        combineMeasurements(q1: number, m1: string, q2: number, m2: string) {
            console.log(`calling combine with ${q1} ${m1} and ${q2} ${m2}`)
            const convertMap = this.getConvertMap()
            const i1 = this.getIndex(m1)
            const i2 = this.getIndex(m2)
            if (!i1 || !i2 || convertMap[i1][i2] == -1) return null
            const [quantity, measurement] = (convertMap[i1][i2] > convertMap[i2][i1])/* && (q1 + q2*convertMap[i2][i1] >= 1) */? 
            [q1 + q2*convertMap[i2][i1], m1]
            : [q2 + q1*convertMap[i1][i2], m2]
            return {q: Math.ceil(quantity*4)/4, m: measurement}
        },

        convertQuarters(quantity: number) {
            if (quantity % 1 != 0) {
                const s = quantity.toString().split('.')
                const start = parseInt(s[0]) > 0 ? s[0] : ''
                let end = ''
                switch(s[1]) {
                    case '25':
                        end = '\xBC'
                        break
                    case '5':
                        end = '\xBD'
                        break
                    case '75': 
                        end = '\xBE'
                        break
                    default:
                        end = `${quantity}`
                        break
                }
                return `${start} ${end}`
            }
            return quantity
        },

        roundQuantity(q: number) {
            return Math.ceil(q)
        },

        getStandardQuantities(recipes: Recipe[]) {
            const measures : IngredientMeasures = {}
            recipes.forEach(rec => {
                rec.sections.forEach(section => {
                    section.ingredients.forEach(ing => {
                        if (!(ing.id in measures)) {
                            let quantity = Math.round(ing.quantityShop) 
                            const quantityLengt = quantity.toString().split('.')[0].length || 0
                            switch(quantityLengt) {
                                case 1:
                                    quantity = 1
                                    break
                                case 2:
                                    quantity = 10
                                    break
                                case 3:
                                    quantity = 100
                                    break
                                case 4:
                                    quantity = 1000
                                    break
                                default:
                                    quantity = 1
                                    break
                            }
                            measures[ing.id] = {
                                quantity: quantity,
                                measure: ing.measureShop
                            }
                        }
                    })
                })
            }) 
            return measures
        }
        
        
    }

