import {Dimensions, Platform} from 'react-native'
import { IS_TABLET, IS_SMALL_DEVICE } from '../../helpers/device';
import {Header} from 'react-navigation'

type size = {
    height: number,
    heightTablet?: number,
    width: number,
    marginTop?: number,
    smallHeight?: number,
    smallWidth?: number,
    maxWidth?: number
}
export const STANDARD_BUTTON_HEIGHT = 60
const statusBarHeight = () => {
    switch(Platform.OS) {
        case('ios'):
            return 20
        case('android'):
            return 24
        default:
            return 24
    }
}

export const WINDOW_SIZE: size = {
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width
}

export const NAVBAR_SIZE: size = {
    height: 60,
    width: 0
}

export const SEARCH_VIEW_SIZES = {
    inputHeight: 40,
    searchResultsHeight: WINDOW_SIZE.height - Header.HEIGHT - statusBarHeight() - 40 - 20,
    searchResultsPositionTop: Header.HEIGHT + statusBarHeight() + 40 + 20
}

const cardMargin = IS_SMALL_DEVICE ? 50 : 100

export const CARD_SIZE: size = {
    height: IS_TABLET ? 230 : 220,
    heightTablet: 400,
    width: WINDOW_SIZE.width-cardMargin,
    maxWidth: 290,
    marginTop: IS_TABLET ? 40 : 15,
    smallHeight: 70,
    smallWidth: WINDOW_SIZE.width-30
}

export const CARD_HEADER_SIZE: size = {
    height: 40,
    width: CARD_SIZE.width
}

export const CARD_IMAGE_SIZE: size = {
    height: CARD_SIZE.height - CARD_HEADER_SIZE.height,
    width: CARD_SIZE.width,
    maxWidth: CARD_SIZE.maxWidth,
    smallHeight: CARD_SIZE.smallHeight,
    smallWidth: 100
}


