import React from 'react'
import {Component} from 'react'
import styles from './styles'
const FlexSearch = require('flexsearch/flexsearch')
import {Props, State, Type, SearchResult} from './types'
import MfSearchBar from './mf_search_bar';
import { getText } from '../../helpers/text/translation';


class Search extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const index = FlexSearch.create({
            //Not sure what to put here to get it to treat åäö in a good way
            encode: 'simple',
            tokenize: 'forward',
            rtl: false,
            resolution: 1
        })
        const exclude = this.props.excludeTypes || []
        for (const recipe of this.props.recipes) {
            if (!exclude.includes('RECIPE'))
                index.add(this.combineIds(recipe.id, recipe.id, 'RECIPE', recipe.name), recipe.name)
            for (const section of recipe.sections) {
                if (!exclude.includes('SECTION'))
                    index.add(this.combineIds(recipe.id, section.id, 'SECTION', section.title),  section.title)
                for (const ingredient of section.ingredients) {
                    if (!exclude.includes('INGREDIENT'))
                        index.add(this.combineIds(recipe.id, ingredient.id, 'INGREDIENT', ingredient.name), ingredient.name)
                }
            }
        }
        for (const category of this.props.categories) {
            if (category.recipes) {
                for (const recipe of category.recipes) {
                    index.add(this.combineIds(recipe.id, category.id, 'TAG', category.name), category.name)
                }
            }
        }
        this.state.index = index
    }

    state: State = {
        search: '',
        index: null
    }

    combineIds(recipeId: number, objectId: number, type: Type, name: string): string {
        return `${recipeId}-${objectId}-${type}-${name.toLocaleLowerCase()}`
    }

    splitIds(id: string): SearchResult {
        const parts = id.split('-')
        return {
            recipeId: parseInt(parts[0]),
            objectId: parseInt(parts[1]),
            type: parts[2] as Type,
            name: parts[3]
        }
    }

    handleSearchResults(res: Array<SearchResult>) {
        const {recipes, resultType, callback} = this.props
        const order: Array<Type> = [
            'RECIPE',
            'TAG',
            'SECTION',
            'INGREDIENT'
        ]
        res.sort((a, b) => {
            if (order.indexOf(a.type) < order.indexOf(b.type)) return -1
            if (order.indexOf(a.type) > order.indexOf(b.type)) return 1
            return 0
        })
        if (resultType == 'SEARCHRESULT') {
            callback(res, this.state.search)
            return
        }

        let recipeIds: Array<number> = []
        let seen = new Set<number>()
        for (const r of res) {
            if (!seen.has(r.recipeId)) {
                recipeIds.push(r.recipeId)
                seen.add(r.recipeId)
            }
        }
        callback(recipeIds, this.state.search)
    }

    protected updateSearch = async (search: string) => {
        this.setState({ search });
        if (this.props.searchChangedCallback) {
            this.props.searchChangedCallback(search)
        }
        const res = await this.search(this.state.index, search)
        this.handleSearchResults(res.map(this.splitIds))
    }

    search = async (index: any, word: string) => {
        const res = index.search(word)
        return res
    }
    render() {
        const {search} = this.state
        return (
            <MfSearchBar
                placeholder={this.props.placeHolder || getText('Skriv här...')}
                onChangeText={this.updateSearch}
                onCancel={this.props.onCancel}
                value={search}
            />

        )
    }

}

export default Search