import React from 'react'
import {Component} from 'react'
import {View, ScrollView} from 'react-native'
import Card from '../../../../components/card'
import {IContainer} from '../../../../interface'
import { IState } from '../../../../model/state';
import { addToFavorites, removeFromFavorites, setRecipe, setRecipeAmount } from '../../../../redux/actions';
import { connect } from 'react-redux';
import { Recipe } from '../../../../model';
import styles from './styles'
import MfContainer from '../../../../components/mf_container';
import { backgrounds } from '../../../../components/mf_container/mf_container';
import MfText, { TEXT_STYLES } from '../../../../components/mf_text';
import { getText } from '../../../../helpers/text/translation';


interface ReduxProps {favorites: number[],recipes: Recipe[]}
interface DispatchProps {
    addToFavorites: Function,
    removeFromFavorites: Function,
    setCurrentRecipe: Function,
    setAmount: Function
}
export interface ReactProps extends IContainer {}
type Props = ReduxProps & ReactProps & DispatchProps
interface State {}

class FavoritesContainer extends Component<Props, State> {
    state = {
        recipes: this.getRecipes(),
        didUpdate: false
    }
    componentDidMount() {
        this.props.setAmount(0)
    }


    getRecipes() {
        const favoriteRecipes: Recipe[] = []
        const {recipes, favorites} = this.props
        recipes.forEach(r => {
            const inRecipes = favorites.indexOf(r.id) > -1
            if (inRecipes) {
                favoriteRecipes.push(r)
            }
        })
        return favoriteRecipes
    }

    getRecipeName(id: number) {
        const {recipes} = this.state
        if (recipes) {
            const r = recipes.find(re => re.id === id)
            if (r) {
                return r.name
            } else {
                return ''
            }
        } else {
            return ''
        }
    }
    
    async cardPressed(id: number) {
        await this.props.setCurrentRecipe(id)
        this.props.setTitle(this.getRecipeName(id))
        this.setState({didUpdate: false})
        this.props.onNavigate()        
    }
    render() {
        const recipes = this.getRecipes()
        return (
            <MfContainer backgroundImage={backgrounds.FAVORITES}>
                <ScrollView contentContainerStyle={styles.container}>
                    {/* <View> */}
                    {recipes.length > 0 ? 
                        recipes.map(recipe => {
                            return <Card title={recipe.name} key={recipe.id} image={{uri: recipe.largeImage}} onPress={() => this.cardPressed(recipe.id)} />
                        })
                        :
                    <View style={styles.emptyContainer}>
                        <MfText textStyle={TEXT_STYLES.SUB_HEADER}>{getText("Favoritlistan är tom")}</MfText>
                        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{getText("Gå in på ett recept och klicka på stjärnan för att lägga till recept som favorit")}</MfText>
                    </View>
                    }
                    {/* </View> */}
                </ScrollView>
            </MfContainer>
        )
    }
}


const mapStateToProps = (state: IState) => {
    return {
        favorites: state.favoriteState.favorites,
        recipes: state.recipeState.recipes
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        addToFavorites: (id: number) => dispatch(addToFavorites(id)),
        removeFromFavorites: (id: number) => dispatch(removeFromFavorites(id)),
        setCurrentRecipe: (id: number) => dispatch(setRecipe(id)),
        setAmount: (amount: number) => dispatch(setRecipeAmount(0)),
    }
}

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(mapStateToProps, mapDispatchToProps)(FavoritesContainer)
