import React from 'react'
import {Component} from 'react'
import { View, Image} from 'react-native';
import Hamburger from '../../../assets/ui/material-menu.png'
import {IMenuButton} from './i_menu'

class MenuButton extends Component<IMenuButton> {
    render() {
        return (
            <View></View>
        )
    }
}

export default MenuButton