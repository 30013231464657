import {StyleSheet, Platform} from 'react-native'
import { PRIMARY_THEME_COLORS, WINDOW_SIZE } from '../../../config/style';
import { IS_TABLET } from '../../../helpers/device';

const buttonHeight = 70

export default StyleSheet.create({
    container: {
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    categoryContainer: {
        width: IS_TABLET ? 300 : '90%',
        margin: 10,
        ...Platform.select({
            web: {
                boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.3)'
            },
            android: {
                shadowColor: PRIMARY_THEME_COLORS.shadowColor,
                shadowOpacity: 0.6,
                shadowRadius: 1,
                shadowOffset: {width: 0, height: 2},
                elevation: 3,
            },
            default: {
                shadowColor: PRIMARY_THEME_COLORS.shadowColor,
                shadowOpacity: 0.6,
                shadowRadius: 1,
                shadowOffset: {width: 0, height: 2}
            }
        })
    },
    headerListItems: {
        minHeight: 50,
        borderBottomWidth: 1,
    },
    listItems: {
        minHeight: 40,
        paddingLeft: 30,
        borderBottomWidth: 0,
        borderTopWidth: 1,
        borderTopColor: PRIMARY_THEME_COLORS.inventory
    },
    actionButton: {
        backgroundColor: PRIMARY_THEME_COLORS.inventory,
        
    },
    buttonLeft: {
        backgroundColor: PRIMARY_THEME_COLORS.red,
        height: buttonHeight,
        width: WINDOW_SIZE.width/2,
        margin: 0
    },
    buttonRight: {
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
        height: buttonHeight,
        width: WINDOW_SIZE.width/2,
        margin: 0
    },
    bottomButtonContainer: {
        height: buttonHeight,
        width: WINDOW_SIZE.width,
        flexDirection: 'row',
        position: 'absolute',
        bottom: 0,
    },
    tabletContainer: {
        flexDirection: 'row',
        height: '100%',
        width: '100%'
    },
    tabletLeft: {
        flex: 1,
        backgroundColor: 'white',
        borderRightColor: PRIMARY_THEME_COLORS.border,
        borderRightWidth: 1,
        ...Platform.select({
            web: {
                boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.3)'
            },
            android: {
                shadowColor: PRIMARY_THEME_COLORS.shadowColor,
                shadowOpacity: 0.5,
                shadowRadius: 8,
                shadowOffset: {width: 0, height: 8},
                elevation: 3,
            },
            default: {
                shadowColor: PRIMARY_THEME_COLORS.shadowColor,
                shadowOpacity: 0.5,
                shadowRadius: 8,
                shadowOffset: {width: 0, height: 8}
            }
        }),
        zIndex: 10
    },
    tabletRight: {
        flex: 2,
    },
    selectedListItem: {
        backgroundColor: PRIMARY_THEME_COLORS.lightGrey
    },
    spacer: {
        height: buttonHeight
    },
    emptyContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        margin: 20,
        padding: 20,
        borderRadius: 10,
        borderColor: 'grey',
        borderWidth: 1
    }
})
