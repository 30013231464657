import React from 'react'
import {Component} from 'react'
import styles from './styles'
import {View, Text} from 'react-native'
import { IState, ICategoryState, IRecipeState } from '../../../../model/state'
import { connect } from 'react-redux'
import { RecipeCategory, Recipe } from '../../../../model'
import { ScrollView} from 'react-native'
import {IContainer} from '../../../../interface'
import Card from '../../../../components/card'
import {setCategory} from '../../../../redux/actions/categories'
import Search from '../../../../components/search';
import MfContainer from '../../../../components/mf_container';
import { backgrounds } from '../../../../components/mf_container/mf_container';
import { firstCapital, getText } from '../../../../helpers/text/translation';
import EmptyCard from '../../../../components/empty_card';
import Loader from '../../../../components/loader';
import { IS_WEB, WEB_SCROLLVIEW_STYLE } from '../../../../config/style/web_helper'

//From mapStateToProps
interface ReduxProps {
    categories: RecipeCategory[],
    dataLoaded: boolean,
    categoryState: ICategoryState
    recipes: IRecipeState
}

interface DispatchProps {
    setCurrentCategory: Function
}

//Passed from JSX component
export interface ReactProps extends IContainer {}

type Props = ReduxProps & ReactProps & DispatchProps

//Internal state
interface State {}

class CategoriesContainer extends Component<Props, State> {
    getCategoryName(id: number) {
        const {categories} = this.props
        const c = categories.find(ca => ca.id === id)
        if (c) {
            return c.name
        } else {
            return ''
        }
    }
    async cardPressed(id: number) {
        await this.props.setCurrentCategory(id)
        this.props.setTitle(firstCapital(this.getCategoryName(id)))
        this.props.onNavigate()
    }

    addEmptyCards(recipes: RecipeCategory[] | undefined) {
        const emptyCards = []
        if (recipes !== undefined) {
            let amount = recipes.length
            while (amount % 3 !== 0) {
                amount++
            }
            const result = amount - recipes.length
            
            for(let i = 0;i<result;i++) {
                emptyCards.push(<EmptyCard key={i} />)
            }
        }
        return emptyCards
    }
    
    render() {
        const {preLoadedImages} = this.props.categoryState
        return (
            <MfContainer backgroundImage={backgrounds.RECIPE}>
                {!preLoadedImages ?
                <Loader text={getText('Detta kan ta en liten stund')} />
                :
                <ScrollView testID={'scrolla nu da!'} contentContainerStyle={[styles.container, IS_WEB && WEB_SCROLLVIEW_STYLE]}>
                    {!this.props.dataLoaded ? 
                    <View>
                        <Text>Laddar recept...</Text>
                        <Text>Du behöver ha tillgång till internet första gången du använder appen</Text>
                    </View>
                     :
                    this.props.categories.map(category => {
                        return <Card onPress={() => this.cardPressed(category.id)} key={category.id} title={firstCapital(category.name)} image={{uri: category.categoryImage}} />
                    })}
                    {this.addEmptyCards(this.props.categories).map(el => el)}
                </ScrollView>  
                }
            </MfContainer>
        )
    }
}

const mapStateToProps = (state: IState) => {
    return { 
        categories: state.categoryState.featuredCategories, 
        categoryState: state.categoryState,
        recipes: state.recipeState,
        dataLoaded: state.categoryState.dataLoaded
    } as ReduxProps
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setCurrentCategory: (id: number) => dispatch(setCategory(id))
    } as DispatchProps
}

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(mapStateToProps, mapDispatchToProps)(CategoriesContainer)