import {StyleSheet} from 'react-native'
import { PRIMARY_THEME_COLORS } from '../../config/style';


// 'clan-pro-bold'
// 'clan-pro-book'
// 'clan-pro-medium'
// 'clan-pro-narr-black'
// 'clan-pro-narr-bold'
// 'clan-pro-narr-book'

export default StyleSheet.create({
    header: {
        fontFamily: 'clan-pro-medium',
        fontSize: 20
    },
    subHeader: {
        fontFamily: 'clan-pro-bold',
        fontSize: 20,
        color: 'grey' 
    },
    plainText: {
        fontFamily: 'clan-pro-narr-book',
        fontSize: 20
    },
    cardHeader: {
       fontSize: 19,
       fontFamily: 'clan-pro-narr-book' 
    },
    boldSubHeader: {

    },
    boldPlain: {
        fontFamily: 'clan-pro-medium',
        fontSize: 20,
    }
})