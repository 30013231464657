import React from 'react'
import {Component} from 'react'
import {View, SafeAreaView, TextInput, ScrollView, Image, ImageSourcePropType, Picker, Keyboard} from 'react-native'
import styles from './styles'
import MfModal, { animationTypes } from '../../../../components/mf_modal'
import MfText, { TEXT_STYLES } from '../../../../components/mf_text';
import { getText } from '../../../../helpers/text/translation';
import Button, { BUTTON_VARIANTS } from '../../../../components/button/button';
import close from '../../../../../assets/ui/close_black.png'
import Touchable from '../../../../components/touchable';
import { IngredientBase } from '../../../../model';
import CheckableItem from '../../../../components/checkable_item';
import Search from '../../../../components/search';
import MfSearchBar from '../../../../components/search/mf_search_bar/index.native';
import KeyboardHideView from '../../../../components/keyboard_hide_view'
import { FlatList } from 'react-native-gesture-handler'


interface IAddToPantryModal {
    visible: boolean,
    onClose: Function,
    onAdd: (addIngredients: {[key: number]: IngredientBase}) => void,
    ingredients: IngredientBase[],
}
class AddToPantryModal extends Component<IAddToPantryModal> {
    state = {
        addedItems: this.getAddedItems(),
        textInput: getText('Sök ingrediens'),
        ingredients: this.props.ingredients,
        textValue: ''
    }

    componentWillReceiveProps(newProps: IAddToPantryModal) {
        if (newProps.ingredients != this.props.ingredients) {
            this.state.ingredients = newProps.ingredients
        }
    }

    getAddedItems() {
        const obj : {[key: string]: IngredientBase} = {}
        return obj
    }
    onClose() {
        this.props.onClose()
    }

    sortOnAdded(newIngredient?: IngredientBase): IngredientBase[] {
        return Array.from(this.props.ingredients).sort((a, b) => {
            if (newIngredient && a.id == newIngredient.id) return -1
            if (newIngredient && b.id == newIngredient.id) return  1
            if (a.name in this.state.addedItems) {
                if (b.name in this.state.addedItems) {
                    return a.name.localeCompare(b.name)
                }
                return -1
            } 
            if (b.id in this.state.addedItems) return 1
            return a.name.localeCompare(b.name)
        })
    }

    onCancelSearch() {
        if (Object.keys(this.state.addedItems).length > 0) {
            this.setState({ingredients: this.sortOnAdded()})
        }
    }

    itemOnPress(ing: IngredientBase) {
        Keyboard.dismiss()
        const obj = JSON.parse(JSON.stringify(this.state.addedItems))
        if (ing.name in obj) {
            delete obj[ing.name]
        } else {
            obj[ing.name] = ing
        }
        if (this.state.textValue.length > 0) {
            this.setState({ingredients: this.sortOnAdded(ing)})
        }
        this.setState({addedItems: obj, textValue: ''})
    }

    onAddDone() {
        this.props.onAdd(this.state.addedItems)
        this.setState({addedItems: this.getAddedItems()})
    }

    onSearchChange(search: string) {
        const arr = Array.from(this.props.ingredients.filter(i => i.name.toLowerCase().includes(search.toLowerCase())))
        this.setState({ingredients: arr, textValue: search})
    }

    render() {
        const {visible} = this.props
        const {ingredients, addedItems, textValue} = this.state
        return (
            <MfModal animationType={animationTypes.SLIDE} transparent={true} visibile={visible} onClose={() => {}}>
                <SafeAreaView style={styles.container}>
                    <View style={styles.contentContainer}>
                        <View style={styles.headerContainer}>
                            <Touchable onPress={() => this.onClose()}>
                                <View>
                                    <Image style={styles.image} source={close as ImageSourcePropType} />
                                </View>
                            </Touchable>
                            <MfText textStyle={TEXT_STYLES.HEADER}>{getText('Lägg till ingredienser')}</MfText>
                            <View style={styles.headerRight}></View>
                        </View>
                        <View style={styles.searchContainer}>
                            <MfSearchBar
                                placeholder={this.state.textInput}
                                onChangeText={(text: string) => this.onSearchChange(text)}
                                value={textValue}
                                onCancel={() => this.onCancelSearch()}
                            />
                            {/* <ScrollView keyboardShouldPersistTaps='handled' >
                            {ingredients.map(ingredient => {
                                return (
                                    <CheckableItem 
                                        key={ingredient.id} 
                                        style={styles.searchItem} 
                                        onPress={() => this.itemOnPress(ingredient)} 
                                        checked={ingredient.name in addedItems} 
                                        customComponent={
                                            <MfText  textStyle={TEXT_STYLES.PLAIN_TEXT}>{ingredient.name}</MfText> 
                                        }/>
                                )
                            })}
                            </ScrollView>  */}
                            <FlatList 
                                keyboardShouldPersistTaps='handled'
                                data={ingredients}
                                renderItem={(item) => {
                                    return (
                                        <CheckableItem 
                                        key={item.item.id} 
                                        style={styles.searchItem} 
                                        onPress={() => this.itemOnPress(item.item)} 
                                        checked={item.item.name in addedItems} 
                                        customComponent={
                                            <MfText  textStyle={TEXT_STYLES.PLAIN_TEXT}>{item.item.name}</MfText> 
                                        }/>
                                    )
                                }}
                            />
                        </View>
                        <KeyboardHideView style={styles.footer}>
                            <Button style={styles.button} variant={BUTTON_VARIANTS.NO_RADIUS} title={getText('Klar')} onPress={() => this.onAddDone()} /> 
                        </KeyboardHideView>
                        
                    </View>
                </SafeAreaView>
            </MfModal>
        )
    }
}

export default AddToPantryModal