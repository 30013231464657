import React from 'react'
import {Component} from 'react'
import {View, Text, TouchableHighlight, Image, ImageSourcePropType} from 'react-native'
import styles from './styles'
import add from '../../../assets/ui/add.png'
import MfText, { TEXT_STYLES } from '../mf_text';
import Touchable from '../touchable';

export enum BUTTON_VARIANTS {
    STANDARD = 'standard',
    MINOR_RADIUS = 'minor_radius',
    NO_RADIUS = 'no_radius',
    FLOATING_ACTION = 'floating_action',
    LARGE = 'large'
}

interface IButton {
    style?: Object
    title: string
    onPress: Function,
    variant?: BUTTON_VARIANTS,
    textColor?: string,
    icon?: ImageSourcePropType,
    bottom?: number,
    borderRadiusStyle?: Object
}

class Button extends Component<IButton> {
    getStyles() {
        switch (this.props.variant) {
            case BUTTON_VARIANTS.NO_RADIUS:
                break
            case BUTTON_VARIANTS.FLOATING_ACTION:
                return styles.floatingAction
            case BUTTON_VARIANTS.MINOR_RADIUS:
                return styles.minorRadius
            default:
                return styles.standard
        }
    }

    renderLargeButton() {
        const {icon, title, style} = this.props
        return (
            <View testID='button_wrapper_outer_large' style={styles.largeButtonContainer}>
                <Touchable onPress={() => this.props.onPress()}>
                    <View style={[styles.largeButton, style]}>
                        <View style={styles.largeButtonTextContainer}>
                            <MfText textStyle={TEXT_STYLES.PLAIN_TEXT} style={styles.largeButtonText}>{title}</MfText>
                        </View>
                        {icon ? <Image style={styles.largeButtonIcon} source={icon} /> : null}
                    </View>
                </Touchable>
            </View>
        )
    }

    render() {
        const {textColor, variant, bottom, borderRadiusStyle} = this.props
        if (variant === BUTTON_VARIANTS.LARGE) {
            return this.renderLargeButton()
        } else if (variant === BUTTON_VARIANTS.FLOATING_ACTION) {
            return (
                <View testID='button_wrapper_outer_floating' style={[styles.floatingActionContainer, {bottom: bottom || 65, borderRadius: 50}]}>
                    <View style={{overflow: 'hidden', borderRadius: 50}}>
                        <Touchable onPress={() => this.props.onPress()} >
                            <View style={[styles.button, this.getStyles(), this.props.style]} >
                            {variant === BUTTON_VARIANTS.FLOATING_ACTION ?
                                <Image style={styles.addImage} source={add as ImageSourcePropType} />
                                :
                                <MfText textStyle={TEXT_STYLES.PLAIN_TEXT} style={[styles.text, textColor ? {color: textColor}: null]}>{this.props.title}</MfText>
                            }
                                </View>
                        </Touchable>
                    </View>
                </View>
            )
        } 
        
        else {
            const radius = () => { switch(variant) {
                case BUTTON_VARIANTS.NO_RADIUS:
                    return 0
                case BUTTON_VARIANTS.MINOR_RADIUS:
                    return 15
                default:
                    return 30
            }
        }
        const noRadius = variant === BUTTON_VARIANTS.NO_RADIUS
            return (
                <View 
                testID='button_wrapper_outer_standard' 
                style={[styles.standardContainer, {bottom: bottom || undefined}, borderRadiusStyle || {borderRadius: radius()}]}>
                    <View style={[{overflow: 'hidden'}, !noRadius && {borderRadius: radius()}, this.props.style]}>
                        <Touchable onPress={() => this.props.onPress()} >
                            <View style={[styles.button, this.getStyles(), this.props.style]} >
                                <MfText textStyle={TEXT_STYLES.PLAIN_TEXT} style={[styles.text, textColor ? {color: textColor}: null]}>{this.props.title}</MfText>
                            </View>
                        </Touchable>
                    </View>
                </View>
            )
        }
        
    }
}

export default Button
