import React from 'react'
import {Component} from 'react'
import FavoritesContainer from './favorites_container'
import { Platform } from 'react-native';
import { Screen } from '../../../interface/screen'
import { Routes } from '../../../navigation/stacks';
import { NavigationScreenProps } from 'react-navigation';
import { PRIMARY_THEME_COLORS } from '../../../config/style';


class Favorites extends Screen {
    state = {
        navTitle: ''
    }

    static navigationOptions = ({navigation}: NavigationScreenProps) => {
        return {
            headerTitle: navigation.getParam('title')
        }
    }

    componentDidMount() {
        if (Platform.OS === 'web') {
            this.props.webNavigation.setNavBarBackgroundColor(PRIMARY_THEME_COLORS.favorites)
        }
    }

    navigate() {
        if (Platform.OS === 'web') {
            this.props.webNavigate(Routes.RECIPE_OVERVIEW, this.state.navTitle)
        } else {
            this.props.navigation.navigate(Routes.RECIPE_OVERVIEW, {title: this.state.navTitle})
        }        
    }

    setNavTitle = (title: string) => {
        this.setState({navTitle: title})
    }
    render() {
        return (
            <FavoritesContainer setTitle={this.setNavTitle} onNavigate={() => this.navigate()} />
        )
    }
}

export default Favorites
