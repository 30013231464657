//Maybe should be strings for easier debugging
export enum Types {
    REQUEST_RECIPES = "REQUEST_RECIPES",
    RECEIVE_RECIPES = "RECEIVE_RECIPES",
    ERROR_RECIPES = "ERROR_RECIPES",
    CHECK_RECIPES = "CHECK_RECIPES",
    SET_CURRENT_RECIPE = "SET_CURRENT_RECIPES",
    SET_RECIPE_AMOUNT = "SET_RECIPE_AMOUNT",
    SET_SECTIONS_DONE = "SET_SECTIONS_DONE",
    SET_ALTERED_SECTIONS = "SET_ALTERED_SECTIONS",
    SET_CAT_PRELOADED_IMAGES = "SET_CAT_PRELOADED_IMAGES",
    SET_REC_PRELOADED_IMAGES = "SET_REC_PRELOADED_IMAGES",
    REQUEST_CATEGORIES = "REQUEST_CATEGORIES",
    RECEIVE_CATEGORIES = "RECEIVE_CATEGORIES",
    ERROR_CATEGORIES = "ERROR_CATEGORIES",
    CHECK_CATEGORIES = "CHECK_CATEGORIES",
    SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY",
    ADD_TO_PANTRY = "ADD_TO_PANTRY",
    REMOVE_FROM_PANTRY = "REMOVE_FROM_PANTRY",
    CLEAR_PANTRY = "CLEAR_PANTRY",
    ADD_TO_FAVORITE = "ADD_TO_FAVORITE",
    REMOVE_FROM_FAVORITE = "REMOVE_FROM_FAVORITE",
    ADD_SHOPPING_LIST = "ADD_SHOPPING_LIST",
    REMOVE_SHOPPING_LIST = "REMOVE_SHOPPING_LIST",
    SET_CURRENT_SHOPPING_LIST = "SET_CURRENT_SHOPPING_LIST",
    ADD_TO_SHOPPING_LIST = "ADD_TO_SHOPPING_LIST",
    UPADTE_SHOPPING_LIST = "UPADTE_SHOPPING_LIST",
    REQUEST_INGREDIENTS = "REQUEST_INGREDIENTS",
    RECIEVE_INGREDIENTS = "RECIEVE_INGREDIENTS",
    CHECK_INGREDIENTS = "CHECK_INGREDIENTS",
    SET_INGREDIENT_MEASURE = "SET_INGREDIENT_MEASURE",
    ERROR_INGREDIENTS = "ERROR_INGREDIENTS",
    ADD_USER_INGREDIENT = "ADD_USER_INGREDIENT",
    REQUEST_NUTRITION = "REQUEST_NUTRITION",
    RECIEVE_NUTRITION = "RECIEVE_NUTRITION",
    ERROR_NUTRITION = "ERROR_NUTRITION",
    CHECK_NUTRITION = "CHECK_NUTRITION",
    SET_SHOW_DOWNLOAD_QUESTION = "SET_SHOW_DOWNLOAD_QUESTION",
    SET_FIRST_TIME = "SET_FIRST_TIME",
    REQUEST_MENU = "REQUEST_MENU",
    RECIEVE_MENU = "RECIEVE_MENU",
    ERROR_MENU = "ERROR_MENU",
    CHECK_MENU = "CHECK_MENU"
}