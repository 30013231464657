import 'expo/build/Expo.fx';
import registerRootComponent from 'expo/build/launch/registerRootComponent';
import React from 'react';
import Web from './src/web/web_container'

function App() {
    return(
        <Web />
    )
}

registerRootComponent(App)