import React from 'react'
import {Start, Settings, Pantry, Categories, RecipeList, RecipeOverview, RecipeInstructions, ShoppingLists, Favorites, ShoppingListDetail, Search, MenuScreen} from '../screens'
import { PRIMARY_THEME_COLORS, WINDOW_SIZE } from '../config/style';
import { IS_TABLET } from '../helpers/device';
import { Icon } from 'react-native-elements';
import { ImageSourcePropType, Image, Text } from 'react-native';


// TODO: Check so that IStack is not interfering with react-navigation (?)

export type IStack = {
    [key: string]: {
        navigationOptions: {
            title: string,
            webRoute: string,
            headerBackTitle: null
            headerLeft?: any,
            headerTitleStyle?: any,
            headerTintColor?: string
            headerStyle?: any
        },
        screen: typeof React.Component,
        isRoot: boolean,
        isStackRoot: boolean
    }
}

export type IStacks = {
    [key: string]: {
        screen: IStack,
        navigationOptions: {
            title: string
        },
        rootRoute: string
    }
}

export enum Routes {
    START = 'Start',
    CATEGORIES = 'Categories',
    PANTRY = 'Pantry',
    RECIPE_LIST = 'RecipeList',
    SHOPPING_LIST = 'ShoppingList',
    RECIPE_OVERVIEW = 'RecipeOverview',
    RECIPE_INSTRUCTIONS = 'RecipeInstructions',
    FAVORITES = 'Favorites',
    SHOPPING_LIST_DETAIL = 'ShoppingListDetail',
    SEARCH = 'Search'
}

const headerTitleStyle = {
    color: 'white',
    fontSize: IS_TABLET ? 45 : 24,
    fontFamily: 'clan-pro-narr-book',
    margin: 0,
    width: IS_TABLET ? undefined : WINDOW_SIZE.width-100
}

const headerStyle = {
    shadowColor: PRIMARY_THEME_COLORS.shadowColor,
    shadowOpacity: 0.8,
    shadowRadius: 2,
    shadowOffset: {width: 0, height: 2},
    height: 60
}

const headerTintColor = 'white'

export const RecipeStack: IStack = {
    Start: {
        screen: Start,
        navigationOptions: {
            title: 'MatFröjd',
            webRoute: '/start',
            headerBackTitle: null,
            headerTitleStyle: headerTitleStyle,
            headerTintColor: headerTintColor,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.recipes}
        },
        isRoot: true,
        isStackRoot: true
    },
    Categories: {
        screen: Categories,
        navigationOptions: {
            title: 'Receptkategorier',
            webRoute: '/categories',
            headerBackTitle: null,
            headerTitleStyle: headerTitleStyle,
            headerTintColor: headerTintColor,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.recipes}
        },
        isRoot: false,
        isStackRoot: false
    },
    RecipeList: {
        screen: RecipeList,
        navigationOptions: {
            title: 'Receptlista',
            webRoute: '/recipe_list',
            headerBackTitle: null,
            headerTintColor: headerTintColor,
            headerTitleStyle: headerTitleStyle,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.recipes}
        },
        isRoot: false,
        isStackRoot: false
    },
    RecipeOverview: {
        screen: RecipeOverview,
        navigationOptions: {
            title: 'Receptöverblick',
            webRoute: '/recipe_overview',
            headerBackTitle: null,
            headerTintColor: headerTintColor,
            headerTitleStyle: headerTitleStyle,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.recipes}
        },
        isRoot: false,
        isStackRoot: false
    },
    RecipeInstructions: {
        screen: RecipeInstructions,
        navigationOptions: {
            title: 'Receptinstruktion',
            webRoute: '/recipe_instructions',
            headerBackTitle: null,
            headerTintColor: headerTintColor,
            headerTitleStyle: headerTitleStyle,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.recipes}
        },
        isRoot: false,
        isStackRoot: false
    },
    ShoppingList: {
        screen: ShoppingLists,
        navigationOptions: {
            title: 'Inköpslista',
            webRoute: '/shopping_list',
            headerBackTitle: null,
            headerTintColor: headerTintColor,
            headerTitleStyle: headerTitleStyle,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.shoppingList}
        },
        isRoot: false,
        isStackRoot: false
    },
    ShoppingListDetail: {
        screen: ShoppingListDetail,
        navigationOptions: {
            title: 'Inköpslista',
            webRoute: '/shopping_list_detail',
            headerBackTitle: null,
            headerTintColor: headerTintColor,
            headerTitleStyle: headerTitleStyle,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.shoppingList}
        },
        isRoot: false,
        isStackRoot: false
    },
    Pantry: {
        screen: Pantry,
        navigationOptions: {
            title: 'Skafferi',
            webRoute: '/pantry',
            headerBackTitle: null,
            headerTintColor: headerTintColor,
            headerTitleStyle: headerTitleStyle,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.inventory}
        },
        isRoot: false,
        isStackRoot: false
    },
    Favorites: {
        screen: Favorites,
        navigationOptions: {
            title: 'Favoriter',
            webRoute: '/favorites',
            headerBackTitle: null,
            headerTintColor: headerTintColor,
            headerTitleStyle: headerTitleStyle,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.favorites}
        },
        isRoot: false,
        isStackRoot: false
    },
    Search: {
        screen: Search,
        navigationOptions: {
            title: 'Sök recept',
            webRoute: '/search',
            headerBackTitle: null,
            headerTintColor: headerTintColor,
            headerTitleStyle: headerTitleStyle,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.recipes}
        },
        isRoot: false,
        isStackRoot: false
    }
}

const InfoStack: IStack = {
    Information: {
        screen: MenuScreen,
        navigationOptions: {
            title: 'Om MatFröjd',
            webRoute: '/om_matfrojd',
            headerBackTitle: null,
            headerTintColor: headerTintColor,
            headerTitleStyle: headerTitleStyle,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.recipes}
        },
        isRoot: false,
        isStackRoot: true
    }
}

const SettingStack: IStack = {
    GeneralSettings: {
        screen: Settings,
        navigationOptions: {
            title: 'Inställningar',
            webRoute: '/installningar',
            headerBackTitle: null,
            headerTitleStyle: headerTitleStyle,
            headerTintColor: headerTintColor,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.recipes}
        },
        isRoot: false,
        isStackRoot: true
    }
}

const MovieStack: IStack = {
    Movies: {
        screen: MenuScreen,
        navigationOptions: {
            title: 'Demofilmer',
            webRoute: '/demofilmer',
            headerBackTitle: null,
            headerTitleStyle: headerTitleStyle,
            headerTintColor: headerTintColor,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.recipes}
        },
        isRoot: false,
        isStackRoot: true
    }
}

const InstructionStack: IStack = {
    Instructions: {
        screen: MenuScreen,
        navigationOptions: {
            title: 'Handbok',
            webRoute: '/handbok',
            headerBackTitle: null,
            headerTitleStyle: headerTitleStyle,
            headerTintColor: headerTintColor,
            headerStyle: {...headerStyle, backgroundColor: PRIMARY_THEME_COLORS.recipes}
        },
        isRoot: false,
        isStackRoot: true
    }
}

export const Stacks = {
    RecipeStack: {
        screen: RecipeStack,
        navigationOptions: {
            title: 'Start',
            customIcon: 'en ikon'
        },
        rootRoute: '/'
    },
    InstructionStack: {
        screen: InstructionStack,
        navigationOptions: {
            title: 'Handbok'
        },
        rootRoute: '/handbok'
    },
    MovieStack: {
        screen: MovieStack,
        navigationOptions: {
            title: 'Demofilmer'
        },
        rootRoute: '/demofilmer'
    },
    InfoStack: {
        screen: InfoStack,
        navigationOptions: {
            title: 'Om MatFröjd'
        },
        rootRoute: '/om_matfrojd'
    },
    SettingStack: {
        screen: SettingStack,
        navigationOptions: {
            title: 'Inställningar'
        },
        rootRoute: '/installningar'
    }
}













