import React from 'react'
import {Component} from 'react'
import { View } from 'react-native'
import styles from './styles'
import MfText, { TEXT_STYLES } from '../mf_text'
import MfSvg from '../mf_svg/'
import AnimatedOpacity from '../animated_opacity';
import AnimatedScale from '../animated_scale';
import { PRIMARY_THEME_COLORS, TEXT_COLORS } from '../../config/style';

interface IListItem {
    primaryContent: string,
    secondaryContent?: string,
    accent: boolean
}


class ListItem extends Component<IListItem> {

   
    getPrimaryContent(animate: boolean) {
        const to = PRIMARY_THEME_COLORS.variation
        const from = TEXT_COLORS.normal
        return <MfText 
            animateColor={animate}
            to={to}
            from={from}
            duration={2000}
            delay={100}
            style={[styles.text, styles.textStyle]} 
            textStyle={TEXT_STYLES.PLAIN_TEXT}>
            {this.props.primaryContent}</MfText>
    }

    getSecondaryContent(animate: boolean) {
        if (this.props.secondaryContent) {
            const to = PRIMARY_THEME_COLORS.variation
            const from = TEXT_COLORS.normal
            return (
                <MfText 
                animateColor={animate}
                to={to}
                from={from}
                duration={2000}
                delay={100}
                style={styles.textStyle} 
                textStyle={TEXT_STYLES.PLAIN_TEXT}>
                {this.props.secondaryContent}</MfText>
            )
        }
        return <View></View>
    }
    getAnimation(children: JSX.Element) {
        const duration = 500
        const delay = 400
        return(
            <AnimatedOpacity from={0.5} to={1} duration={duration} delay={delay} onAnimationEnd={() => {}}>
                <AnimatedScale from={0.1} to={1.3} end={1} onAnimationEnd={() => {}} duration={duration} delay={delay}>
                    {children}
                </AnimatedScale>
            </AnimatedOpacity>
        )
        
    }
    render() {
        if (this.props.accent) {
            return (
                <View style={styles.container}>
                    {this.getAnimation(this.getPrimaryContent(true))}
                    {this.getAnimation(this.getSecondaryContent(true))}
                </View>
            )
        } else {
            return (
                <View style={styles.container}>
                    {this.getPrimaryContent(false)}
                    {this.getSecondaryContent(false)}
                </View>
            )
        }
        
    }
}

export default ListItem
