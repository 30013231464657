import React from 'react'
import {Component} from 'react'
import styles from './styles'
import {View, Platform, ScrollView} from 'react-native'
import Heart from '../../../../../assets/ui/heart_filled.png'
import HeartEmpty from '../../../../../assets/ui/heart.png'
import Shopping from '../../../../../assets/mf_ui/handla-ikon-cc4132.png'
import { IState, IAlteredSections } from '../../../../model/state';
import { Recipe, Ingredient, ShoppingList, ShoppingIngredient, RawShoppingList, Nutrition} from '../../../../model';
import { IContainer } from '../../../../interface';
import { connect } from 'react-redux';
import MfText, { TEXT_STYLES } from '../../../../components/mf_text';
import ListItem from '../../../../components/list_item';
import Button, {BUTTON_VARIANTS} from '../../../../components/button/button';
import { getText } from '../../../../helpers/text/translation';
import CheckButton from '../../../../components/check_button';
import { setRecipeAmount, setAlteredSections, setCurrentSectionsDone } from '../../../../redux/actions/recipes';
import AdjustModal from './adjust_modal'
import { IAlternatives } from './adjust_modal/adjust_modal';
import InfoModal from './info_modal';
import { addToFavorites, removeFromFavorites, addShoppingList, addToShoppingList} from '../../../../redux/actions';
import AddShoppingListModal from './add_shopping_list_modal';
import { calculateNutritions } from '../../../../helpers/nutritions_handlers';
import NutritionModal from './nutrition_modal/';
import Touchable from '../../../../components/touchable';
import MfCollapsible from '../../../../components/mf_collapsible';
import FavoriteAnimation from '../../../../components/favorite_animation';
import AnimatedScroll from './animated_scroll';
import { IS_TABLET } from '../../../../helpers/device';
import QuantityHandler from '../../../../helpers/quantity_handler'
import { IS_WEB } from '../../../../config/style/web_helper'
import { CheckedSections } from '../../recipe_instructions/recipe_instructions_container/recipe_instructions_container'
// import ShowMoreIcon from '../../../../../assets/mf_ui/'


interface ReduxProps { 
    currentRecipe: Recipe, 
    currentAlteredSections: IAlteredSections, 
    favorites: number[],
    shoppingLists: ShoppingList[],
    nutritions: Nutrition[]
    currentAmount: number }
interface DispatchProps {
    setAmount: Function, 
    setSectionsDone: Function
    setAlteredSections: Function, 
    addToFavorites: Function, 
    addShoppingList: (key: RawShoppingList) => void,
    addToShoppingList: (id: number, s: ShoppingIngredient[]) => void,
    removeFromFavorites: Function}
export interface ReactProps extends IContainer {
    onModalClose: Function,
    showModal: boolean
}
type Props = ReduxProps & ReactProps & DispatchProps
interface State {}

interface ing {[key:string] : Ingredient}

class RecipeOverviewContainer extends Component<Props, State> {
    state = {
        scrollPosition: 0,
        didChangePortions: this.getDidChangePortions(),
        showModal: false,
        showShoppingModal: false,
        alteredSections: this.findAlteredSections(),
        alteredIngredients: [],
        ingredients: this.getIngredients(false),
        showNutritionModal: false,
        showLongDescription: false
    }
    constructor(p: Props) {
        super(p)
    }
    componentDidMount() {
        this.setAmount()
        // this.getIngredients(true)
    }

    componentWillUnmount() {
        if (Platform.OS !== 'web') {
            this.props.setAmount(0)
        }
        
        this.props.setSectionsDone({})
    }

    getNutritions(nuts: Nutrition[], ings: ing, splitAmount: number) {
        // const {nutritions} = this.props
        // const {ingredients} = this.state
        return calculateNutritions(ings, nuts, splitAmount)
        
        
    }

    setAmount() {
        const {currentRecipe, currentAmount} = this.props
        if (currentAmount === 0) {
            this.props.setAmount(currentRecipe.minServings)
        }
    }

    isAlteredIngredient(id: number) {
        return this.state.alteredIngredients.find(a => a === id) !== undefined
    }

    getDidChangePortions() {
        const {currentAmount, currentRecipe} = this.props
        return currentAmount === currentRecipe.maxServings
    }
    
    
    changePortions(amount: number) {
        // const {ingredients} = this.state
        const ingredients = JSON.parse(JSON.stringify(this.state.ingredients))
        const {currentAmount} = this.props
        Object.keys(ingredients).forEach(obj => {
            ingredients[obj].quantity = ingredients[obj].quantity / currentAmount * amount 
            
        })
        this.props.setAmount(amount)
        .then(() => this.setState({ingredients: ingredients, didChangePortions: !this.state.didChangePortions}))
        
    }

    findAlteredSections() {
        const {sections} = this.props.currentRecipe
        const {currentAlteredSections} = this.props
        const altered : IAlternatives = {}
        sections.forEach(section => {
            if (section.isAlternative) {
                const currentChoice = (section.altId in currentAlteredSections) ? currentAlteredSections[section.altId] : section.altId
                if (section.altId in altered) {
                    altered[section.altId].alternatives.push(section)
                    altered[section.altId].alteredSection = currentChoice
                } else {
                    altered[section.altId] = {
                        title: section.altTitle,
                        alteredSection: currentChoice,
                        alternatives: [section]
                    }
                }
            }
        })
        return altered
    }

    hasAlteredSections() {
        const {alteredSections} = this.state
        return Object.entries(alteredSections).length > 0
    }


    getIngredients(didMount: boolean) {
        const result : ing  = {}
        const { sections } = this.props.currentRecipe
        const {currentAlteredSections, currentAmount, currentRecipe} = this.props
        const ai: number[] = []
        sections.forEach((section, index) => {
            if (!(section.id in currentAlteredSections) && !section.isAlternative) {
                section.ingredients.forEach((ingredient) => {
                    const id = ingredient.id
                    if (id in result) { 
                        const i2 = result[id]
                        const comb = QuantityHandler.combineMeasurements(i2.quantity, i2.measure, ingredient.quantity, ingredient.measure)
                        if (comb) {
                            result[id].quantity = comb.q
                            if (i2.measure != comb.m) {
                                result[id].measure = ingredient.measure
                                result[id].measureFull = ingredient.measureFull
                                result[id].measurePlural = ingredient.measurePlural
                                result[id].measureFullPlural = ingredient.measureFullPlural
                            }
                        }
                    } else {
                        result[id] = JSON.parse(JSON.stringify(ingredient))
                        const order = index + '' + result[id].sortOrder
                        result[id].sortOrder = parseInt(order)
                    }
                })
            } else {
                const alteredSection = sections.find((a) => a.id === currentAlteredSections[section.id])
                if (typeof alteredSection === typeof section && alteredSection !== undefined)  {
                    alteredSection.ingredients.forEach((ingredient) => {
                        const id = ingredient.id
                        if (id in result) { 
                            const i2 = result[id]
                            const comb = QuantityHandler.combineMeasurements(i2.quantity, i2.measure, ingredient.quantity, ingredient.measure)
                            if (comb) {
                                result[id].quantity = comb.q
                                if (i2.measure != comb.m) {
                                    result[id].measure = ingredient.measure
                                    result[id].measureFull = ingredient.measureFull
                                    result[id].measurePlural = ingredient.measurePlural
                                    result[id].measureFullPlural = ingredient.measureFullPlural
                                }
                            }
                        } else {
                            result[id] = JSON.parse(JSON.stringify(ingredient))
                            const order = index + '' + result[id].sortOrder
                            result[id].sortOrder = parseInt(order)
                        }
                        ai.push(id)
                    })
                }
            }
        })
        if (didMount) {
            this.setState({alteredIngredients: ai})
        }
        return result
    }

    hideAdjustModal() {
        this.setState({showModal: false})
    }
    showModal() {
        this.setState({showModal: true})
    }
    variationChange(id: number, alt: number) {
        const cas = this.props.currentAlteredSections
        const del = id === alt
        if (del) {
            delete cas[id]
        } 
        const ingredients = this.getIngredients(true)
        const {currentAmount} = this.props
        Object.keys(ingredients).forEach(obj => {
            ingredients[obj].quantity = ingredients[obj].quantity * (currentAmount/2) 
        })
        this.props.setAlteredSections(Object.assign({}, this.props.currentAlteredSections, del? cas : {[id]: alt}))
        .then(() => this.setState({
            alteredSections: this.findAlteredSections(),
            ingredients: ingredients
        }, () => 
            this.props.setSectionsDone({})
        ))

    }
    modalButtonOnPress() {
        this.hideAdjustModal()
    }

    addRecipeToFavorites() {
        this.props.addToFavorites(this.props.currentRecipe.id)
    }
    removeFromFavorites() {
        this.props.removeFromFavorites(this.props.currentRecipe.id)
    }
    recipeIsFavorite() {
        const {favorites, currentRecipe} = this.props
        return favorites.indexOf(currentRecipe.id) > -1
    }
    addNewShoppingList(title: string) {
        this.props.addShoppingList({title: title, ingredients: this.getShoppingIngredients()})
    }

    addIngredientsToShoppingList(id: number) {
        const {shoppingLists} = this.props
        const s = shoppingLists.find(sl => sl.id === id)
        if (s) {
            this.props.addToShoppingList(id, this.getShoppingIngredients())
        } else {
            console.log('didnt add to shopping list')
        }
    }
    // WARNING: Ändra quantityShop om det behövs i framtiden.
    getShoppingIngredients() {
        const {ingredients} = this.state
        const {currentRecipe, currentAmount} = this.props
        const shoppingIngredients: ShoppingIngredient[] = []
        Object.keys(ingredients).map(i => {
            let shoppingIngredient = ingredients[i] as ShoppingIngredient
            shoppingIngredient.quantityShop = shoppingIngredient.quantityShop / currentRecipe.minServings * currentAmount
            shoppingIngredients.push(shoppingIngredient)
        })
        return shoppingIngredients
    }

    getPortionsText(min: boolean) {
        const {currentRecipe} = this.props
        if (min) {
            const por = currentRecipe.minServings > 1 ? currentRecipe.servingsLabelPlural : currentRecipe.servingsLabelSingular
            return `${QuantityHandler.convertQuarters(currentRecipe.minServings)} ${por}`
        } else {
            const por = currentRecipe.maxServings > 1 ? currentRecipe.servingsLabelPlural : currentRecipe.servingsLabelSingular
            return `${QuantityHandler.convertQuarters(currentRecipe.maxServings)} ${por}`
        }
    }

    renderIngredienList() {
        const {ingredients} = this.state
        const {currentRecipe, currentAmount} = this.props
        const sortedIngredients : Ingredient[] = []
        Object.keys(ingredients).forEach(ing => {
            sortedIngredients.push(ingredients[ing])
        })
        
        sortedIngredients.sort((a, b) => a.sortOrder - b.sortOrder)
        return (
            <View style={{marginBottom: 100}}>
                {sortedIngredients.map(ingredient => {
                    const displayQuantity = QuantityHandler.convertQuarters(ingredient.quantity)
                    const displayMeasure = QuantityHandler.shouldUsePlural(ingredient, currentAmount, currentRecipe.minServings) ? 
                        ingredient.measurePlural : ingredient.measure
                    return (
                        <ListItem 
                        key={ingredient.name} 
                        primaryContent={ingredient.name} 
                        accent={(!this.state.showModal && this.isAlteredIngredient(ingredient.id))}
                        // accent={false}
                        secondaryContent={`${displayQuantity} ${displayMeasure}`} />
                    )
                })}
            </View>
        )
    }

    renderDescriptionText() {
        const {currentRecipe} = this.props
        const {showLongDescription} = this.state

        if (IS_TABLET) {
            return (
                <View style={styles.descriptionContainer}>
                    <MfText style={styles.description} textStyle={TEXT_STYLES.PLAIN_TEXT}>{currentRecipe.shortDescription}</MfText>
                    <MfText style={styles.description} textStyle={TEXT_STYLES.PLAIN_TEXT}>{currentRecipe.longDescription}</MfText>
                </View>
            )
        } else {
            return (
                <View style={styles.descriptionContainer}>
                    <MfText style={styles.description} textStyle={TEXT_STYLES.PLAIN_TEXT}>{currentRecipe.shortDescription}</MfText>
                    <MfCollapsible collapsed={!showLongDescription}>
                        <MfText style={styles.description} textStyle={TEXT_STYLES.PLAIN_TEXT}>{currentRecipe.longDescription}</MfText>
                    </MfCollapsible>
                    <Touchable onPress={() => this.setState({showLongDescription: !showLongDescription})}>
                        <MfText style={styles.showMore} textStyle={TEXT_STYLES.PLAIN_TEXT}>{getText(showLongDescription? 'Visa mindre' : 'Visa mer')}</MfText>
                        
                    </Touchable>
                </View>
            )
        }
    }

    renderPortionOptions() {
        const {currentRecipe} = this.props
        return (
            <View style={styles.portionContainer}>
                {/* <MfText style={styles.portionText} textStyle={TEXT_STYLES.PLAIN_TEXT}>{getText('Ingredienser för')}</MfText> */}
                <View style={styles.portionButtonContainer}>
                    <CheckButton checked={!this.state.didChangePortions} title={this.getPortionsText(true)} onPress={() => this.changePortions(currentRecipe.minServings)} />
                    <CheckButton checked={this.state.didChangePortions} title={this.getPortionsText(false)} onPress={() => this.changePortions(currentRecipe.maxServings)} />
                </View>
            </View>
        )
    }

    renderButtons() {
        const isWeb = Platform.OS === 'web'
        const hasAlteredSections = this.hasAlteredSections()
        const leftStyle = IS_TABLET ? [styles.buttonLeft, styles.buttonLeftTablet] : styles.buttonLeft
        const radiusLeft = IS_TABLET && styles.buttonLeftTablet
        const radiusRight = IS_TABLET && [styles.buttonRightTablet, !hasAlteredSections && styles.singleButtonRightTablet ]
        const rightStyle = IS_TABLET ? [styles.buttonRight, hasAlteredSections ? styles.buttonRightTablet : styles.singleButtonRightTablet] : hasAlteredSections ? styles.buttonRight : styles.singleButtonRight
        const container = IS_TABLET ? [styles.buttonContainer, styles.buttonContainerTablet] : styles.buttonContainer
        const webContainer = IS_TABLET ? [{marginBottom: 10}, styles.buttonContainerWeb] : styles.buttonContainerWeb
        return (
            <View style={isWeb ? webContainer : container}>
                {hasAlteredSections ?
                <Button 
                variant={BUTTON_VARIANTS.NO_RADIUS} 
                style={[leftStyle, isWeb && styles.buttonWidthWeb]} 
                borderRadiusStyle={radiusLeft}
                title={getText('Alternativ')} 
                onPress={() => this.showModal()} />
                : null}
                <Button 
                variant={BUTTON_VARIANTS.NO_RADIUS} 
                style={[rightStyle, isWeb && styles.buttonWidthWeb]} 
                borderRadiusStyle={radiusRight}
                title={getText('Laga mat')} 
                onPress={() => this.props.onNavigate()} />
            </View>
        )
    }

    onShowNutritions() {
        this.props.onModalClose()
        this.setState({showNutritionModal: true})
    }

    
    render() {
        const {ingredients, showNutritionModal, showLongDescription} = this.state
        const {currentRecipe, currentAmount} = this.props
        const isFavorite = this.recipeIsFavorite()
        const actions = [
            {
                icon: isFavorite ? {uri: Heart} : {uri: HeartEmpty},
                onPress: isFavorite ? () => this.removeFromFavorites() : () => this.addRecipeToFavorites(),
                component: <FavoriteAnimation onAdd={() => this.addRecipeToFavorites()} onRemove={() => this.removeFromFavorites()} isFavorite={isFavorite} />
            },
            {
                icon: {uri: Shopping},
                onPress: () => this.setState({showShoppingModal: true})
            }
        ]
        return (
            <View style={IS_WEB ? styles.containerWeb : styles.container}>
                <AddShoppingListModal onClose={() => this.setState({showShoppingModal: false})} 
                    onCreate={(title: string) => this.addNewShoppingList(title)} 
                    onAdd={(id: number) => this.addIngredientsToShoppingList(id)}
                    shoppingLists={this.props.shoppingLists}
                    visible={this.state.showShoppingModal} />
                <NutritionModal 
                    nutritions={this.getNutritions(this.props.nutritions, this.state.ingredients, currentAmount)}
                    splitAmount={currentAmount}
                    measurement={currentRecipe.servingsLabelSingular} 
                    visible={showNutritionModal} 
                    onClose={() => this.setState({showNutritionModal: false})}/>
                <InfoModal 
                    tips={currentRecipe.tips} 
                    onShowNutritions={() => this.onShowNutritions()}
                    visible={this.props.showModal} 
                    onClose={() => this.props.onModalClose()} />
                <AdjustModal 
                    visible={this.state.showModal} 
                    onClose={() => this.hideAdjustModal()} 
                    itemOnPress={(id: number, alt: number) => this.variationChange(id, alt)}
                    buttonOnPress={() => this.modalButtonOnPress()}
                    sections={this.state.alteredSections}/>
                {IS_TABLET ? 
                <View style={styles.tabletContainer}>
                    <View style={styles.tabletLeft}>
                        <AnimatedScroll actions={actions} image={{uri: currentRecipe.largeImage}} disableScroll={true} tablet={true}>
                            {this.renderDescriptionText()}
                        </AnimatedScroll>
                        {this.renderButtons()}
                    </View>
                    <View style={styles.tabletRight}>
                        <ScrollView>
                            {this.renderPortionOptions()}
                            {this.renderIngredienList()}
                        </ScrollView>
                        
                    </View>
                    
                </View>
                :
                <View style={IS_WEB ? styles.containerWebMobile : styles.container}>
                    <AnimatedScroll actions={actions} image={{uri: currentRecipe.largeImage}} tablet={false}>
                        <View style={styles.contentContainer}>
                            {this.renderDescriptionText()}
                            {this.renderPortionOptions()}
                            {this.renderIngredienList()}
                        </View>
                    </AnimatedScroll>
                    {this.renderButtons()}
                </View>
                }
                {/* { Platform.OS === 'web' ? <View style={{height: 140}}></View> : null } */}
            </View>
        )
    }
}

const mapStateToProps = (state: IState) => {
    return {
        currentRecipe: state.recipeState.currentRecipe,
        currentAmount: state.recipeState.currentAmount,
        favorites: state.favoriteState.favorites,
        currentAlteredSections: state.recipeState.currentAlteredSections,
        shoppingLists: state.shoppingListState.shoppingLists,
        nutritions: state.nutritionState.nutritions
    } as ReduxProps
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setAmount: (amount: number) => dispatch(setRecipeAmount(amount)),
        setAlteredSections: (sections: IAlteredSections) => dispatch(setAlteredSections(sections)),
        addToFavorites: (id: number) => dispatch(addToFavorites(id)),
        removeFromFavorites: (id:number) => dispatch(removeFromFavorites(id)),
        addShoppingList: (s: RawShoppingList) => dispatch(addShoppingList(s)),
        addToShoppingList: (id: number, s: ShoppingIngredient[]) => dispatch(addToShoppingList(id, s)),
        setSectionsDone: (b: CheckedSections) => dispatch(setCurrentSectionsDone(b))
    }
}

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(mapStateToProps, mapDispatchToProps)(RecipeOverviewContainer)
