import {StyleSheet, Platform} from 'react-native'
import { PRIMARY_THEME_COLORS, WINDOW_SIZE, SECONDARY_THEME_COLORS } from '../../../../config/style';
import { IS_TABLET } from '../../../../helpers/device';

export default StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        overflow: 'hidden'
    },

    // varför påverkar inte detta webversion?
    contentContainer: {
        
        // flex: 1,
    },
    // MarginTop: 60 behövs pga av navbar
    contentContainerWeb: {
        // marginTop: 60
    },
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 35
    },
    checkable: {
        borderBottomWidth: 1,
        borderBottomColor: 'grey',
    },
    white: {
        backgroundColor: 'white',
    },
    green: {
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
    },
    actionButton: {
        backgroundColor: PRIMARY_THEME_COLORS.shoppingList,
    },
    deleteButton: {
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
        height: 60,
        position: Platform.select({
            web: 'fixed',
            default: undefined
        }),
        bottom: Platform.select({
            web: '0px',
            default: undefined
        })
    },
    itemTitle: {
        flex: 1
    },
    itemQuantity: {
        minWidth: 60,
        textAlign: 'right',
        marginRight: 5,
        marginLeft: 5
    },
    checkedText: {
        color: PRIMARY_THEME_COLORS.recipes,
        fontStyle: 'italic'
    },
    collapsedContainer: {
        height: 50,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    collapsedItem: {
        width: IS_TABLET ? WINDOW_SIZE.width/3*2/3 : WINDOW_SIZE.width/3,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center'
    },
    collapsedLeft: {
        backgroundColor: PRIMARY_THEME_COLORS.ligthRed,
    },
    collapsedImage: {
        height: 30,
        width: 30
    },
    collapsedMiddle: {
        backgroundColor: PRIMARY_THEME_COLORS.lightBlue,
    },
    collapsedRight: {
        backgroundColor: PRIMARY_THEME_COLORS.lighterGreen,
    },
    collapsedText: {
        fontSize: 40,
        color: 'white'
    },
    categoryTitle: {
        backgroundColor: SECONDARY_THEME_COLORS.lightGreen,
        // marginTop: 10,
        height: 55,
        paddingLeft: 10,
        justifyContent: 'center',
        
    },
    emptyContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        margin: 20,
        padding: 20,
        borderRadius: 10,
        borderColor: 'grey',
        borderWidth: 1
    },
    catDivider: {
        // height: 10
    },
    bottomSpacer: {
        height: 150,
    }
})