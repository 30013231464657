import React from 'react'
import { Component } from 'react';
import { SearchBar } from 'react-native-elements'
import {Props} from './types'
import styles from './styles'
import { getText } from '../../../helpers/text/translation';

class MfSearchBar extends Component<Props> {
    render() {
        const onCancel = this.props.onCancel || (() => null)
        return (
            <SearchBar
                placeholder={this.props.placeholder}
                onChangeText={this.props.onChangeText}
                value={this.props.value}
                inputStyle={[styles.input, styles.backgroundColor]}
                inputContainerStyle={styles.backgroundColor}
                lightTheme
                onCancel={() => onCancel()}
                platform='ios'
                cancelButtonTitle={getText('Avbryt')}
                containerStyle={styles.containerNative}
                cancelButtonProps={{color: 'black'}}
            />
        )
    }
}

export default MfSearchBar